import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NumberFormat from 'react-number-format';
import {httpClient} from "../../../core/HttpClient";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    link: {
        color: '#1abc9c'
    },
    content: {
    },
});

const PhoneFormatCustom = React.forwardRef(function PhoneFormatCustom(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

export default function MyInformation() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [contact, setContact] = useState({displayName: '', mobile: ''});
    const [error, setError] = useState({displayName: false, mobile: false});
    const [open, setOpen] = useState({success: false, fail: false});

    useEffect(() => {
        console.log('MyInformation');
        let url = process.env.REACT_APP_API_BASE_URL + `/secure/customer/profile`;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setContact({displayName: res.data.displayName, mobile: res.data.mobile, pictureUrl: res.data.pictureUrl});
                }
            });
    }, []);

    const handleSave = () => {
        if(_validation()){
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/profile';
            httpClient.put(url, contact)
                .then(res => {
                    setError({displayName: false, mobile: false});
                    setOpen({success: true, fail: false});
                    let customer = {displayName: res.data.displayName, mobile: res.data.mobile, pictureUrl: res.data.pictureUrl};
                    localStorage.setItem('customer', JSON.stringify(customer));
                }).catch(e=>{
                setOpen({success: false, fail: true});
            });
        }
    };

    const handleChange = ({ target }) => {
        setContact({ ...contact, [target.name]: target.value });
    };

    const handleClose = () => {
        setOpen({success: false, fail: false});
    };

    function _validation() {
        let result = true;
        let error = {displayName: false, mobile: false};
        if(!contact.displayName){
            result = false;
            error.displayName = true;
        }
        if(!contact.mobile ||
            contact.mobile.length !== 10 ||
            !contact.mobile.startsWith('0')
        ){
            result = false;
            error.mobile = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    return (
        <Box mt={2}>
            <Card className={classes.root}>
                <CardContent className={classes.content}>
                    <Box>
                        <TextField id="outlined-basic"
                                   label={t('profile.name')}
                                   variant="outlined"
                                   fullWidth={true}
                                   error={error.displayName}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   inputProps={{
                                       autoComplete: 'new-password',
                                   }}
                                   name="displayName" value={ contact.displayName } onChange={ handleChange } required />
                    </Box>
                    <Box mt={2}>
                        <TextField id="outlined-basic"
                                   label={t('profile.mobile')}
                                   variant="outlined"
                                   fullWidth={true}
                                   name="mobile" type="tel"
                                   value={contact.mobile}
                                   onChange={ handleChange }
                                   error={error.mobile}
                                   InputLabelProps={{
                                       shrink: true,
                                   }}
                                   InputProps={{
                                       inputComponent: PhoneFormatCustom,
                                   }}
                                   required />
                    </Box>
                    <Box mt={2}>
                        <Button autoFocus variant="contained" fullWidth={true} size="large" disableElevation onClick={handleSave}>
                            {t('common.save')}
                        </Button>
                    </Box>
                </CardContent>
            </Card>
            <Snackbar open={open.success} anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose} >
                <Alert variant="filled" severity="success">
                    {t('common.message.saveSuccess')}
                </Alert>
            </Snackbar>
            <Snackbar open={open.fail} anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose} >
                <Alert variant="filled" severity="error">
                    {t('common.message.saveFail')}
                </Alert>
            </Snackbar>
        </Box>
    );
}
