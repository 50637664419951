import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import RecommendIcon from '@mui/icons-material/Recommend';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import NumberFormat from 'react-number-format';
import {TextI18n, getTextI18n} from "../../TextI18n";
import {TextI18nTruncate} from "../../TextI18nTruncate";

const useStyles = makeStyles({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
    }
});

const modalStyle = {
    margin: '10px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1
};

const _calculateTotalOptional = (optional)=>{
    let total = 0;
    const keys = Object.keys(optional);
    keys.forEach(key=>{
        if(key !== 'total'){
            total += optional[key].price;
        }
    });
    return total;
};

export function ProductView({shopName, shopState, product, item, onClose, onAdditionalChange, onOptionalChange}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [additional, setAdditional] = useState('');
    const [optional, setOptional] = useState({total: 0});
    const [open, setOpen] = useState(false);
    console.log('[ProductView]');

    useEffect(() => {
        console.log('ProductView');
        if(item.additional){
            setAdditional(item.additional);
        }
        if(item.optional){
            setOptional(item.optional);
        }
    },[item, product]);

    const handleChange = ({target})=>{
        setAdditional(target.value);
        onAdditionalChange(target.value);
    };

    const handleToggle = (opt, value) => () => {
        if(opt.multipleSelect){
            if(optional[opt._id]){
                const currentIndex = optional[opt._id].checked.indexOf(value._id);
                const newOptional = {...optional};

                if (currentIndex === -1) {
                    newOptional[opt._id].checked.push(value._id);
                    newOptional[opt._id].checkedName.push(value.name);
                    newOptional[opt._id].price += value.price;
                } else {
                    newOptional[opt._id].checked.splice(currentIndex, 1);
                    newOptional[opt._id].checkedName.splice(currentIndex, 1);
                    newOptional[opt._id].price -= value.price;
                }

                newOptional.total = _calculateTotalOptional(newOptional);
                setOptional(newOptional);
                onOptionalChange(newOptional);

            } else {
                const newOptional = {...optional};
                newOptional[opt._id] = {
                    name: opt.name,
                    checked: [value._id],
                    checkedName: [value.name],
                    price: value.price
                };
                newOptional.total = _calculateTotalOptional(newOptional);
                setOptional(newOptional);
                onOptionalChange(newOptional);
            }
        } else {
            const newOptional = {...optional};
            newOptional[opt._id] = {
                name: opt.name,
                checked: [value._id],
                checkedName: [value.name],
                price: value.price
            };
            newOptional.total = _calculateTotalOptional(newOptional);
            setOptional(newOptional);
            onOptionalChange(newOptional);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return(
        <div>
            <div>
                <Card sx={{borderRadius: '0px'}} className={classes.root}>
                    <CardHeader
                        style={{position: 'absolute', padding: '0px', marginLeft: '10px', zIndex: 99}}
                        action={
                            <IconButton
                                edge="start"
                                color="default"
                                aria-label="close"
                                onClick={onClose}
                            >
                                <HighlightOff fontSize="large" style={{background: 'white', borderRadius: '18px'}} />
                            </IconButton>
                        }
                    />
                    {product.images && product.images[0] &&
                    <CardMedia
                        onClick={handleOpen}
                        style={{cursor: 'pointer'}}
                        className={classes.media}
                        image={`${process.env.REACT_APP_CDN_BASE_URL}/${product.images[0].permission}/o/${product.images[0].name}`}
                        title={getTextI18n(product.name)}
                    />
                    }
                    {(!product.images || !product.images[0]) &&
                    <CardMedia
                        className={classes.media}
                        image="https://cdn.foodkub.com/public/assets/empty.png"
                    />
                    }
                </Card>
            </div>
            <Box pb={12}>
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box mr={1}>
                        <Typography component={'span'} variant="h6">
                            <TextI18nTruncate value={product.name} lines={2}></TextI18nTruncate>
                        </Typography>
                        </Box>
                        {product.recommend &&
                        <Box display="flex" alignItems="center">
                            <RecommendIcon color="error" fontSize="small" />
                            <Typography color="error"  variant="caption" style={{marginLeft: '0.1rem'}}>
                                {t('product.recommend')}
                            </Typography>
                        </Box>
                        }
                    </Box>
                    <Box>
                        <Typography component={'span'} variant="subtitle1" color="textSecondary">
                            <TextI18nTruncate value={product.description} lines={2}></TextI18nTruncate>
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         style={{width: '100%'}}>
                        <div>{t('product.price')}</div>
                        <div><NumberFormat value={product.price} displayType={'text'}
                                           thousandSeparator={true} prefix={'฿ '}/></div>
                    </Box>
                    <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    <Box pb={2}>
                        <Typography variant="body1">
                            <TextI18n value={product.detail}></TextI18n>
                        </Typography>
                    </Box>
                    {(!product.oos && shopState !== 'close') &&
                    <>
                        {product.options.map((opt, index) => (
                            <Box pb={2} key={`opt_${opt._id}`}>
                                <Card className={classes.root} variant="outlined">
                                    <CardContent style={{paddingBottom: '10px'}}>
                                        <Typography variant="subtitle1">
                                            {!opt.multipleSelect &&
                                            <span style={{ color: 'red', 'marginRight': '0.5rem' }}>*</span>
                                            }
                                            <TextI18n value={opt.name}></TextI18n>
                                        </Typography>
                                        <form autoComplete="false">
                                            <List style={{padding: '0'}}>
                                                {opt.items.map((opti, itemIndex) => (
                                                    <ListItem key={'option_'+index+'_'+itemIndex} style={{padding: '0'}} button onClick={handleToggle(opt, opti)}>
                                                        <ListItemIcon style={{minWidth: '0'}}>
                                                            {opt.multipleSelect &&
                                                            <Checkbox
                                                                checked={optional[opt._id]?optional[opt._id].checked.indexOf(opti._id) !== -1: false}
                                                                name={opti._id}
                                                                color="primary"
                                                                tabIndex={-1}
                                                                disableRipple
                                                                style={{paddingLeft: '0'}}
                                                                inputProps={{ 'aria-labelledby': opti._id }}
                                                            />
                                                            }
                                                            {!opt.multipleSelect &&
                                                            <Radio
                                                                checked={optional[opt._id]?optional[opt._id].checked.indexOf(opti._id) !== -1: false}
                                                                value={opti._id}
                                                                name={opt._id}
                                                                style={{paddingLeft: '0'}}
                                                                inputProps={{ 'aria-label': opt._id }}
                                                            />
                                                            }
                                                        </ListItemIcon>
                                                        <ListItemText id={opti._id} primary={getTextI18n(opti.name)} style={{ overflow: "hidden", wordWrap: 'break-word', paddingRight: '0.5rem' }} />
                                                        {opt.showPrice &&
                                                        <Box style={{minWidth: '60px', textAlign: 'right'}}>
                                                            <Typography variant="body1"
                                                                        color="inherit" style={{whiteSpace: 'nowrap'}}>{t('common.bahtSign')} {opti.price>0 && <>+</>}<NumberFormat value={opti.price} displayType={'text'} thousandSeparator={true} /></Typography>
                                                        </Box>
                                                        }
                                                    </ListItem>
                                                ))
                                                }
                                            </List>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Box>
                        ))
                        }
                        <Box pb={4}>
                            <form autoComplete="false">
                                <TextField label={t('cart.additional')}
                                           fullWidth={true}
                                           name="additional"
                                           value={additional}
                                           type="text"
                                           onChange={handleChange}/>
                            </form>
                        </Box>
                    </>
                    }
                </CardContent>
            </Box>
            <Modal
                open={open}
                style={{overflow:'scroll'}}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Box>
                        <IconButton
                            edge="start"
                            color="default"
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <HighlightOff fontSize="large" style={{background: 'white', borderRadius: '18px'}} />
                        </IconButton>
                        {t('common.images')}
                    </Box>
                    {product.images.map((image, i) => (
                        <img key={i}
                             style={{width: '100%', marginTop: '8px'}}
                             src={`${process.env.REACT_APP_CDN_BASE_URL}/${image.permission}/o/${image.name}`}
                             alt={getTextI18n(product.name)}
                        />
                    ))
                    }
                </Box>
            </Modal>
        </div>
    )
}