import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddressDialog from '../../../components/Location/AddressDialog'
import { httpClient } from "../../../core/HttpClient";

function _distance(lat1,
                   lat2, lon1, lon2)
{

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 =  lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2),2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    return(c * r);
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        marginLeft: '10px',
    }
});

export default function AddressSelection({context, onChange}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [openMap, setOpenMap] = useState(false);
    const [needSelection, setSeedSelection] = useState(context.outOfDelivery===1?'warning.main':'primary.main');
    const [addressList, setAddressList] = useState({show: false, loading: false, list: []});

    console.log('[AddressSelection]');

    const cid = localStorage.getItem('cid');

    const handleOpenMap = () => {
        let token = localStorage.getItem('token');
        if(token){
            // load address
            setAddressList({show: true, loading: true, list: []});
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';

            httpClient.get(url)
                .then(res => {
                    if(res.data){
                        setAddressList({show: true, loading: false, list: res.data});
                    } else {
                        setAddressList({show: true, loading: false, list: []});
                    }
                    setOpenMap(true);
                    // setContext({ ...context, openMap: true, success: false, fail: false, warning: false });
                });
        } else {
            setOpenMap(true);
            // setContext({ ...context, openMap: true, success: false, fail: false, warning: false });
        }
    };

    const handleReloadAddressList = () => {
        let token = localStorage.getItem('token');
        if(token){
            // load address
            setAddressList({show: true, loading: true, list: []});
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';

            httpClient.get(url)
                .then(res => {
                    if(res.data){
                        setAddressList({show: true, loading: false, list: res.data});
                    } else {
                        setAddressList({show: true, loading: false, list: []});
                    }
                });
        }
    };

    const handleAddressChange = (customerLocation) => {
        console.log('handleAddressChange');
        let cacheData = JSON.parse(localStorage.getItem('cache'));
        let shop = context.shop;
        if(cacheData && cacheData.cid === cid){
            let newCacheData = {...cacheData, date: new Date(), location: customerLocation};
            localStorage.setItem('cache', JSON.stringify(newCacheData));
            const cd = _distance(customerLocation.coordinates.lat, shop.lat,
                customerLocation.coordinates.lng, shop.lng);
            let outOfDelivery = 0;
            if(cd > shop.distance){
                outOfDelivery = 1;
                setSeedSelection('warning.main');
            } else {
                setSeedSelection('primary.main');
            }
            setOpenMap(false);
            onChange({mode: context.mode, location: customerLocation, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false});
        } else {

            let newCacheData = {cid: cid, date: new Date(), location: customerLocation, cart: null};
            localStorage.setItem('cache', JSON.stringify(newCacheData));
            const cd = _distance(customerLocation.coordinates.lat, shop.lat,
                customerLocation.coordinates.lng, shop.lng);
            let outOfDelivery = 0;
            if(cd > shop.distance){
                outOfDelivery = 1;
                setSeedSelection('warning.main');
            } else {
                setSeedSelection('primary.main');
            }
            setOpenMap(false);
            onChange({mode: context.mode, location: customerLocation, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false});

        }
    };

    const handleCloseMap = () => {
        setOpenMap(false);
        // setContext({ ...context, openMap: false, success: false, fail: false, warning: false });
    };

    return (
        <Box>
            <CardHeader style={{paddingBottom: '0'}} title={<Typography variant="h6" component="div">
                {t('address.delivery')}
            </Typography>} />
            <CardContent>
                <Box p={1} border={1} borderColor={needSelection} borderRadius="5px"
                     display="flex" alignItems="center" onClick={handleOpenMap}>
                    <React.Fragment>
                        <Box>
                            <LocationOnIcon color="secondary" style={{cursor: 'pointer'}} />
                        </Box>
                        <Box>
                            {needSelection === 'warning.main' &&
                            <Typography variant="button" component="div" className={classes.title}
                                        style={{cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                {t('address.selectDelivery')}
                            </Typography>
                            }
                            {needSelection === 'primary.main' &&
                            <Typography variant="body1" component="div" className={classes.title}
                                        style={{cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                {context.location && context.location.contact &&
                                <div>
                                    <Typography display="block">
                                        {context.location.contact.myAddress && context.location.contact.name}
                                        {!context.location.contact.myAddress && context.location.coordinates && t('address.myPIN')}
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        {context.location.contact.myAddress && context.location.contact.address}
                                        {!context.location.contact.myAddress && context.location.contact.address}
                                    </Typography>
                                </div>
                                }
                            </Typography>
                            }
                        </Box>
                    </React.Fragment>
                </Box>
            </CardContent>
            {context.shop && context.shop.lat &&
            <AddressDialog open={openMap} shop={context.shop} location={context.location}
                           addressList={addressList}
                           onAddressChange={handleReloadAddressList}
                           onClose={handleCloseMap}
                           onChange={handleAddressChange} />
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={addressList.loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </Box>
    )
};