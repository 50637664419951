import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {httpClient} from "../../../core/HttpClient";


export default function PaymentChannel({mode, onChange}) {
    const { t } = useTranslation();

    const [selectedPayment, setSelectedPayment] = useState({method: ''});
    const [payment, setPayment] = useState({cash: false, foundsTransfer: false, foundsTransferList: [], promptPay: false, promptPayQrCodeImage: {}});
    const [paymentList, setPaymentList] = useState([]);

    useEffect(() => {
        console.log('PaymentChannel');
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/payments';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setPayment(res.data);

                    let list = [];
                    if(res.data.cash){
                        list.push({method: 'cash', value: 'cash'});
                    }
                    if(res.data.foundsTransfer){
                        list.push({method: 'foundsTransfer', value: 'foundsTransfer'});
                    }
                    if(res.data.promptPay){
                        list.push({method: 'promptPay', value: 'promptPay'});
                    }

                    setPaymentList(list);

                    if(list[0] && !selectedPayment.method){
                        let newSelectedPayment = {...selectedPayment, method: list[0].method, foundsTransferList: res.data.foundsTransferList, promptPayQrCodeImage: res.data.promptPayQrCodeImage};
                        setSelectedPayment(newSelectedPayment);
                        onChange(newSelectedPayment);
                    }

                }
            });
    }, [selectedPayment, setSelectedPayment, onChange]);

    const handleSelectPayment = (item)=>{
        let newSelectedPayment = {...selectedPayment, method: item.value, foundsTransferList: payment.foundsTransferList};
        setSelectedPayment(newSelectedPayment);
        onChange(newSelectedPayment);
    };

    return (
        <div>
            <Card sx={{borderRadius: '0px'}} pt={2}>
                <CardHeader style={{paddingBottom: '5px'}} title={<Typography variant="h6" component="div">
                    {t('payment.method')}
                </Typography>} />
                <Box mb={2}>
                    {paymentList.length === 0 &&
                    <Box display="flex" justifyContent="center" p={2} m={2}>
                        <Typography>
                            {t('payment.notFound')}
                        </Typography>
                    </Box>
                    }
                    {paymentList.length > 0 &&
                    <RadioGroup aria-label="payment method" name="paymentMethod" value={selectedPayment.method}>
                        <List>
                            {paymentList.map((item, index) => (
                                <ListItem
                                    key={index}
                                    role={undefined}
                                    dense
                                    divider={paymentList.length !== index+1}
                                    button
                                    onClick={(e)=>handleSelectPayment(item)}
                                >
                                    <ListItemIcon>
                                        <Radio value={item.method} checked={selectedPayment.method === item.method} />
                                    </ListItemIcon>
                                    <ListItemText id={item.method}
                                                  primary={t(`payment.${item.value}`)}
                                                  secondary={t(`payment.${item.value}Description${mode==='PICKUP'?'Pickup':''}`)}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </RadioGroup>
                    }
                </Box>
            </Card>
        </div>
    );
}
