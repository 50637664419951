import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import {isDesktop} from 'react-device-detect';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Circle } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './style.css'
import {markerIcon} from "../AddressDialog";

const redIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

const LocationMarker = (props) => {
    const [position] = useState(props.position);
    const markerRef = useRef(null);

    const marker = markerRef.current;
    if (marker != null) {
        marker.setLatLng(props.position);
    }

    return position === null ? null : (
        <Marker position={position} ref={markerRef} icon={redIcon}></Marker>

    )
};

export default function StoreLocation({location, shop}) {
    const [map, setMap] = useState(null);
    console.log('[StoreLocation]');
    if (map != null) {
        map.fitBounds([
            [location.coordinates.lat, location.coordinates.lng],
            [shop.lat, shop.lng]
        ]);
    }

    return (
        <Box style={{width: 'inherit', height: 'inherit'}}>
            <MapContainer className="static-map"
                          center={[location.coordinates.lat, location.coordinates.lng]}
                          zoom={location.zoom}
                          {...interactionOptions}
                          whenCreated={setMap}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker effectOn={this} position={[location.coordinates.lat, location.coordinates.lng]} />
                {shop && shop.lat && shop.lng &&
                    <>
                    <Marker icon={markerIcon} position={[shop.lat, shop.lng] } ></Marker>
                    <Circle
                        center={{lat: shop.lat, lng:shop.lng}}
                        color="#1abc9c"
                        fillColor="#1abc9c"
                        radius={shop.distance * 1000}/>
                    </>
                }

            </MapContainer>
        </Box>
    )
};