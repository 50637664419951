import React from 'react';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import FoodOrderPayment from "../FoodOrderPayment";
import {TextI18n} from "../../TextI18n";


const MyProductItemOptional = ((optional, i) => {
    let productOptions = [];
    if(optional){
        let keys = Object.keys(optional);
        keys.forEach(key=>{
            if(key !== 'total'){
                productOptions.push(optional[key]);
            }
        });
    }

    return (
        <div key={i}>
            {productOptions.map((opt, j) => (
                <Box key={`optional-${j}`}>
                    {opt.checkedName && opt.checkedName.map((itemName, k) => (
                        <Box key={`${i}_${j}_${k}`}
                             display="flex"
                             alignItems="center"
                             justifyContent="start">
                            <CheckBoxIcon style={{'fontSize': '1.2rem', 'marginRight': '3px'}}/>
                            <Typography color="textSecondary" sx={{fontSize: '0.85rem'}} style={{wordBreak: 'break-word'}}>
                                <TextI18n value={itemName}></TextI18n>
                            </Typography>
                        </Box>
                    ))}
                </Box>
            ))}
        </div>
    )
});

const MyProductItem = (({item, i, t}) => {
    return (
        <Box key={`key-${i}`} p={2}>
            <Box style={{wordWrap: 'break-word'}} fontWeight="fontWeightBold">
                <TextI18n value={item.product.name}></TextI18n>
            </Box>
            <div>
                {item.optional &&
                MyProductItemOptional(item.optional, i)
                }
                {item.additional &&
                <Box style={{wordWrap: 'break-word'}}>
                    <Typography variant="body1" color="textSecondary" sx={{fontSize: '0.85rem'}}>{item.additional}</Typography>
                </Box>
                }
            </div>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
                <div style={{textAlign: 'right'}}>{t('order.qty')} x {item.qty}</div>
                <Typography variant="subtitle2" sx={{'marginLeft': '10px'}}>
                    <NumberFormat value={item.price * item.qty} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '5px', 'marginTop': '5px'}} />
        </Box>
    )
});

export default function FoodOrderItems({value}) {
    const { t } = useTranslation();

    return (
        <Card variant="outlined" style={{width: '100%'}}>
            <Box display="flex" justifyContent="center" mt={1}>
                <Typography variant="h6" component="div">
                    {t('order.foodOrders')}
                </Typography>
            </Box>
            {value &&
            <>
                {value.items.map((item, i) => (
                    <Box key={i}><MyProductItem item={item} i={i} t={t} /></Box>
                ))}
            </>
            }
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center"
                     style={{width: '100%'}}>
                    <div>{t('order.total')}</div>
                    <div><NumberFormat value={value.total} displayType={'text'}
                                       thousandSeparator={true} prefix={'฿'}/></div>
                </Box>
                <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                <Box display="flex" justifyContent="space-between" alignItems="center"
                     style={{width: '100%'}}>
                    <div>
                        <span>{t('order.delivery')}</span>
                        <Typography variant="body1" color="textSecondary" sx={{fontSize: '0.85rem'}}>{t('cart.distance')} {value.deliveryDistance} {t('cart.distanceUnit')}</Typography>
                    </div>
                    <div><NumberFormat value={value.deliveryPrice} displayType={'text'}
                                       thousandSeparator={true} prefix={'฿'}/></div>
                </Box>
                <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                <Box display="flex" justifyContent="space-between" alignItems="center"
                     fontWeight="fontWeightBold"
                     style={{width: '100%'}}>
                    <div>{t('common.total')}</div>
                    <div><NumberFormat value={value.grandTotal} displayType={'text'}
                                       thousandSeparator={true} prefix={'฿'}/></div>
                </Box>
                <FoodOrderPayment value={value} />
            </CardContent>
        </Card>
    );
}
