import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RecommendIcon from '@mui/icons-material/Recommend';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Slide from '@mui/material/Slide';
import NumberFormat from 'react-number-format';
import Sticky from 'react-sticky-el';
import CartUtil from "../../../core/CartUtil";
import {TextI18n} from "../../TextI18n";
import {TextI18nTruncate} from "../../TextI18nTruncate";
import ProductViewDialog from "../ProductViewDialog";
import ProductControlDialog from "../ProductControlDialog";
import {ProductEdit} from "../ProductEdit";
import {Context} from "../../../core/Context";
import {getCache, getMode} from "../../../core/LocalStorageUtil";

const shapeStyles = { backgroundColor: 'primary.main', padding: '0 8px 0 8px', color: '#fff', fontSize: '0.8rem' };
const shapeInvertStyles = { backgroundColor: '#fff', padding: '0 8px 0 8px', color: '#1abc9c', fontSize: '1 rem' };

const useStyles = makeStyles({
    root: {},
    link: {
        color: '#1abc9c'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    qty: {
        minWidth: '1.5rem',
        textAlign: 'center'
    },
    avatar: {
        backgroundColor: 'none'
    }
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const selectedBox = (cart, value) => {
    let selectedValue = 0;

    if(cart && cart.products[value._id]){
        selectedValue = cart.products[value._id].qty;
    }

    return (
        <div>
            <Box hidden={selectedValue<1} sx={shapeStyles}>{selectedValue}</Box>
        </div>
    )
};


export default function ProductList({list,shop}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const history = useHistory();
    const [context] = useContext(Context);
    const [open, setOpen] = useState({add: false, edit: false});
    const [item, setItem] = useState({index: -1, additional: '', optional: {total: 0}, qty: 1});
    const [selectProduct, setSelectProduct] = useState({qty: 1});
    const [productGroups] = useState(list);

    let mode = getMode();
    let cacheData = getCache(cid);
    let cart = cacheData.cart;

    console.log('[ProductList]');

    useEffect(() => {
        console.log('ProductList');
        // Trigger Selected Product.
        let product = JSON.parse(localStorage.getItem('pid'));
        if(product){
            let cartQty = 0;
            if(cart && cart.products[product._id]){
                cartQty = cart.products[product._id].qty;
                setOpen({add: false, edit: true});
            }else{
                setItem({index: -1, additional: '', optional: {total: 0}, qty: 1});
                setOpen({add: true, edit: false});
            }
            let oos = !product.open ||  product.remaining-cartQty < 1;
            setSelectProduct({...product, qty:1, oos: oos, cartQty: cartQty});
            localStorage.removeItem('pid');
        }
    }, [cart]);

    const handleClickOpen = (product) => {
        if(mode === 'VIEW'){
            setSelectProduct({...product});
            setOpen({add: true, edit: false});
        } else {
            let cartQty = 0;
            if(cart && cart.products[product._id]){
                cartQty = cart.products[product._id].qty;
                setOpen({add: false, edit: true});
            }else{
                setItem({index: -1, additional: '', optional: {total: 0}, qty: 1});
                setOpen({add: true, edit: false});
            }
            let oos = !product.open || product.remaining-cartQty < 1;
            setSelectProduct({...product, qty:1, oos: oos, cartQty: cartQty});
        }
    };

    const handleClose = () => {
        setOpen({add: false, edit: false});
    };

    const handleSubmitCart = () => {
        let data = {...context};
        history.push({
            pathname: `/m/${cid}/c`,
            contextData: data
        });
    };

    const handleChange = (value) => {
        console.log('handleChange');
        if(value.mode === 0){
            // update
            new CartUtil(cid).updateCart(value.product, value.item);
        } else if(value.mode === 1){
            // add
            new CartUtil(cid).addToCart(value.product, value.item);
        } else if(value.mode === -1){
            // remove
            new CartUtil(cid).removeCartItem(value.product, value.item);
        }

        setOpen({add: false, edit: false});
    };

    const handleSelectProductEdit = ({value, index}) => {
        console.log('handleSelectProductEdit');
        let cartQty = 0;
        if(cart && cart.products[value._id]){
            cart.products[value._id].items.forEach((item,i)=>{
                if(index !== i){
                    cartQty+=item.qty;
                }
            })
        }
        let newSelectProduct = {...selectProduct, qty: value.qty, oos: false, cartQty: cartQty};
        setSelectProduct(newSelectProduct);
        setItem({index: index, additional: value.additional, optional: value.optional, qty: value.qty});
        setOpen({add: true, edit: false});
    };

    const handleAddAnother = () => {
        setItem({index: -1, additional: '', optional: {total: 0}, qty: 1});
        setOpen({add: true, edit: false});
    };

    return (
        <div className={classes.root}>
            {productGroups.length === 0 &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Typography>
                    {t('product.notFound')}
                </Typography>
            </Box>
            }
            {productGroups.length > 0 &&
            <Card sx={{borderRadius: '0px'}} pt={2}>
                <CardHeader title={<Typography variant="h6" component="div">
                    {t('product.title')}
                </Typography>} />
                <List className="SortableList" subheader={<li />}>
                    {productGroups.map((productGroup, sectionId) => (
                        <li key={`section-${sectionId}`} className={classes.listSection}>
                            <ul className={classes.ul}>
                                {productGroup.systemData === 0 &&
                                <ListSubheader disableSticky={true}><TextI18n value={productGroup.name}></TextI18n></ListSubheader>
                                }
                                {productGroup.products.map((value, index) => (
                                    <ListItem key={`item-${value._id}`}
                                              button
                                              divider={index !== productGroup.products.length-1?true:false}
                                              onClick={e=>{handleClickOpen(value)}}>
                                        <ListItemAvatar>
                                            <Avatar variant="square" sx={{ width: 89 , height: 89}} className={classes.avatar}>
                                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={
                                                    value.images[0]?
                                                        `${process.env.REACT_APP_CDN_BASE_URL}/${value.images[0].permission}/s/${value.images[0].name}`: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
                                                }
                                                     alt={value.name.i18n['en']}/>
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText style={{marginLeft: '10px'}}>
                                            <React.Fragment>
                                                <Typography variant="h6">
                                                            <span style={{fontSize: '1.2rem'}}>
                                                                <TextI18nTruncate value={value.name} lines={2}></TextI18nTruncate>
                                                            </span>
                                                </Typography>
                                                <Box display="flex" justifyContent="space-between" >
                                                    <Box>
                                                        {value.recommend &&
                                                            <Box display="flex" alignItems="center">
                                                                <RecommendIcon color="error" fontSize="small" />
                                                                <Typography color="error"  variant="caption" style={{marginLeft: '0.1rem'}}>
                                                                    {t('product.recommend')}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                        <Typography variant="subtitle2" color="primary">
                                                            <NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={'฿ '} />
                                                        </Typography>
                                                    </Box>
                                                    {selectedBox(cart, value)}
                                                    {(shop.state !== 'close' && (!value.open || value.remaining < 1)) &&
                                                    <Typography variant="subtitle2" color="secondary">{t('product.outOfStock')}</Typography>
                                                    }
                                                </Box>
                                            </React.Fragment>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </ul>
                        </li>
                    ))}
                </List>
                {cart && cart.qty > 0 &&
                <Box pt={4} pb={4} pl={1} pr={1}>
                    <Sticky mode="bottom">
                        <div style={{paddingBottom: '30px', backgroundImage: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.99))'}}>
                            <Button variant="contained"
                                    size="large"
                                    fullWidth={true}
                                    onClick={handleSubmitCart}
                                    style={{zIndex: 99, fontWeight: 'bold'}}>
                                <Box display="flex" justifyContent="space-between" alignItems="center"
                                     style={{fontSize: '1.1rem', width: '100%'}}>
                                    <span style={shapeInvertStyles}>{cart.qty}</span>
                                    <span>{t('cart.myCart')}</span>
                                    <span><NumberFormat value={cart.total} displayType={'text'} thousandSeparator={true}
                                                        prefix={'฿ '}/></span>
                                </Box>
                            </Button>
                        </div>
                    </Sticky>
                </Box>
                }
            </Card>
            }
            {mode === 'VIEW' &&
            <ProductViewDialog
                product={selectProduct}
                open={open.add}
                onClose={handleClose}
            />
            }
            {mode !== 'VIEW' &&
            <ProductControlDialog
                shop={shop}
                product={selectProduct}
                item={item}
                open={open.add}
                onChange={handleChange}
                onClose={handleClose}
            />
            }
            <Dialog fullScreen
                    open={open.edit}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    style={{top: 'auto'}} >
                <ProductEdit value={selectProduct}
                             cid={cid}
                             onClose={handleClose}
                             onEdit={handleSelectProductEdit} />
                <div style={
                    {
                        position: 'fixed',
                        top: 'auto',
                        bottom: 0,
                        padding: '8px',
                        background: '#fff',
                        width: '100%',
                        borderRadius: '4px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                        overflow: 'hidden',
                        zIndex: 999
                    }
                }>
                <Button style={{fontSize: '1.1rem', marginBottom: '1rem'}}
                        variant="contained"
                        onClick={handleAddAnother}
                        color="primary"
                        size="large"
                        fullWidth={true}
                        disableElevation autoFocus>
                    <span>{t('cart.addAnother')}</span>
                </Button>
                </div>
            </Dialog>
        </div>
    )
}