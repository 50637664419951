import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StoreIcon from '@mui/icons-material/Store';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PhoneIcon from '@mui/icons-material/Phone';
import NumberFormat from 'react-number-format';
import {TextI18n} from "../../TextI18n";

const useStyles = makeStyles({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
    }
});

export default function ShopInfo({shop, time}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState({image:{}});

    console.log('[ShopInfo]');

    useEffect(() => {
        console.log('ShopInfo');
        setState(shop);
    }, [shop]);

    return (
        <div>
            <Card sx={{borderRadius: '0px'}} className={classes.root}>
                <CardHeader
                    title={<TextI18n value={state.name}></TextI18n>}
                    subheader={<Stack direction="row" justifyContent="flex-start"
                                      alignItems="center" spacing={1} mt={1}>
                        {time &&
                            <>
                            <StoreIcon />
                            {state.state === 'open' && state.active &&
                            <Typography variant="body1" component="div" color="primary">
                                {t('shop.status.open')}
                            </Typography>
                            }
                            {state.state === 'close' && state.active &&
                            <Typography variant="body1" component="div" color="secondary">
                                {t('shop.status.close')}
                            </Typography>
                            }
                            {state.active === false &&
                            <Typography variant="body1" component="div" color="secondary">
                                {t('shop.status.temporarilyClosed')}
                            </Typography>
                            }
                            {state.active &&
                            <>
                                <ScheduleIcon />
                                <Typography variant="body1" component="div" noWrap>
                                    {state.openTime} - {state.closeTime}
                                </Typography>
                            </>
                            }
                            </>
                        }
                        <PhoneIcon />
                        <Typography variant="body1" component="div" noWrap>
                            <a href={`tel:${state.phone}`}><NumberFormat value={state.phone} displayType={'text'} format="###-###-####" /></a>
                        </Typography>
                    </Stack>}
                />
                {state.image && state.image.name &&
                <CardMedia
                    className={classes.media}
                    image={`${process.env.REACT_APP_CDN_BASE_URL}/${state.image.permission}/o/${state.image.name}`}
                    title="Profile"
                />
                }
                {!state.image &&
                <CardMedia
                    className={classes.media}
                    image="https://cdn.foodkub.com/public/assets/empty.png"
                    title="Profile"
                />

                }
            </Card>
        </div>
    )
}