import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Route, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import StoreIcon from '@mui/icons-material/Store';
import L from 'leaflet';
import CssBaseline from "@mui/material/CssBaseline";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SettingDialog from '../../components/Profile/SettingDialog'
import { Context } from "../../core/Context";

import 'leaflet/dist/leaflet.css';
import './style.css'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {getMode} from "../../core/LocalStorageUtil";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    header: {
        marginBottom: '10px',
    },
});

const InsideLayout = ({ title, backTo, children}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const mode = getMode();
    const history = useHistory();
    const [customer] = useState({...JSON.parse(localStorage.getItem('customer')), token: localStorage.getItem('token')});
    const [context, setContext] = useState({mode: mode});
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSetting, setOpenSetting] = useState(false);
    const open = Boolean(anchorEl);

    useEffect(() => {
        console.log('InsideLayout');
        if(cid){
            localStorage.setItem('cid', cid);
        }
    }, [cid]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleBack = () => {
        let _cid = cid || localStorage.getItem('cid');
        if(backTo === 'history'){
            history.push(`/h/${_cid}`);
        } else {
            history.push(`/m/${_cid}`);
        }
    };

    const handleMyProfile = () => {
        handleMenuClose();
        history.push('/p');
    };

    const handleMyOrderHistory = () => {
        handleMenuClose();
        let _cid = cid || localStorage.getItem('cid');
        history.push(`/h/${_cid}`);
    };

    const handleSetting = (value) => {
        handleMenuClose();
        setOpenSetting(value);
    };

    const handleLogout = () => {
        localStorage.clear();
        window.sessionStorage.setItem("autoLogin", "no");
        window.location.href = `/m/${cid}`;
    };

    const handleLogin = (event) => {
        const clientId = process.env.REACT_APP_LINE_CLIENT_ID;
        const redirectUri = `${process.env.REACT_APP_BASE_URL}/login?cid=${cid}`;
        const state = Math.floor(100000 + Math.random() * 900000);

        const url =
            'https://access.line.me/oauth2/v2.1/authorize?response_type=code' +
            '&client_id=' +
            clientId +
            '&redirect_uri=' +
            redirectUri +
            '&state=' +
            state +
            '&scope=profile';

        window.location.href = url;
    };

    return (
        <div>
            <CssBaseline />
            <header className={classes.header}>
                <AppBar elevation={0}
                        color="light"
                        position="fixed"
                        sx={{borderBottom: '1px solid #e7e7e7'}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit"
                                    onClick={handleBack}
                                    aria-label="menu" sx={{ mr: 2 }}>
                            {(cid || localStorage.getItem('cid')) && backTo === 'store' &&
                            <StoreIcon />
                            }
                            {(cid || localStorage.getItem('cid')) && ['history','home'].indexOf(backTo) > -1 &&
                            <KeyboardBackspaceIcon />
                            }
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            <Box display="flex" justifyContent="center">
                                {t(title)}
                            </Box>
                        </Typography>

                        {!customer.token &&
                        <IconButton edge="end"
                                    onClick={handleLogin}
                                    color="inherit" aria-label="menu">
                            <AccountCircleIcon />
                        </IconButton>
                        }

                        {customer.token &&
                        <IconButton edge="end"
                                    onClick={handleMenu}
                                    color="inherit" aria-label="menu">
                            <Avatar alt="profile" src={customer.pictureUrl} />
                        </IconButton>
                        }

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem dense onClick={(e) => handleMyProfile()}>{t('profile.myInformation')}</MenuItem>
                            <MenuItem dense onClick={(e) => handleMyOrderHistory()}>{t('profile.ordersHistory')}</MenuItem>
                            <MenuItem dense onClick={(e) => handleSetting(true)}>{t('setting.changeLanguage')}</MenuItem>
                            <Divider sx={{my: 0.5}}/>
                            <MenuItem dense onClick={(e) => handleLogout()}>{t('logout')}</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Toolbar />
            </header>
            <div>
                <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
            </div>
            <SettingDialog open={openSetting} onClose={(e) => handleSetting(false)} />
        </div>
    );
};

const InsideLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                return <InsideLayout {...rest}><Component {...props} /></InsideLayout>
            }}
        />
    );
};

export default InsideLayoutRoute;