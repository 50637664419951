import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import qs from 'qs';

export default function Login(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = useState(true);

    const handleLogin = (event) => {
        const cid = localStorage.getItem('cid');
        if(cid){
            const clientId = process.env.REACT_APP_LINE_CLIENT_ID;
            const redirectUri = `${process.env.REACT_APP_BASE_URL}/login?cid=${cid}`;
            const state = Math.floor(100000 + Math.random() * 900000);

            const url =
                'https://access.line.me/oauth2/v2.1/authorize?response_type=code' +
                '&client_id=' +
                clientId +
                '&redirect_uri=' +
                redirectUri +
                '&state=' +
                state +
                '&scope=profile';

            window.location.href = url;
        }

    };

    useEffect(() => {
        const queryString = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        const code = queryString.code;
        const cid = queryString.cid;
        if(code && cid){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({code: code, redirectUri: process.env.REACT_APP_BASE_URL+'/login?cid='+cid, cid: cid})
            };
            const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
            fetch(url, requestOptions)
                .then(results => results.json())
                .then(data => {
                    if(data.token){
                        localStorage.setItem('token', data.token);
                        let customer = {
                            displayName: data.displayName,
                            pictureUrl: data.pictureUrl,
                            mobile: data.mobile,
                            address: data.address
                        };
                        localStorage.setItem('customer', JSON.stringify(customer));
                        history.push(`/m/${cid}`);
                    } else {
                        setState(false);
                    }
                });
        } else {
            setState(false);
        }
    }, [history, props]);

    return (
        <div>
            {state &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!state &&
            <div>
                <Box m={2}>
                    <Alert variant="filled" severity="error">{t('common.message.accessDenied')}</Alert>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                    <Button onClick={handleLogin}>{t('login.retry')}</Button>
                </Box>
            </div>
            }
        </div>
    )
}