import firebase from "firebase/app";
import "firebase/database";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyCjHYUA26hl6I7P3o4xLEICy8kLFqAANPc",
    authDomain: "foodkub.firebaseapp.com",
    databaseURL: "https://foodkub-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "foodkub",
    storageBucket: "foodkub.appspot.com",
    messagingSenderId: "209225889289",
    appId: "1:209225889289:web:79630bb66851348da96ad8",
    measurementId: "G-JMQPN62V9N"
};
firebase.initializeApp(firebaseConfig);
export default firebase;