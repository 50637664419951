import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Route, useParams, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import L from 'leaflet';
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import jwt_decode from "jwt-decode";
import {Helmet} from "react-helmet";
import Footer from '../../components/Footer'
import LocationAlert from '../../components/Location/LocationAlert'
import SettingDialog from '../../components/Profile/SettingDialog'
import ModeDialog from '../../components/ModeDialog'
import ModeSelection from '../../components/ModeSelection'
import OrdersTracking from '../../components/OrdersTracking'
import firebase from '../../core/Firebase'
import { Context } from "../../core/Context";
import { httpClient } from "../../core/HttpClient";
import { getCache, getMode, saveMode } from "../../core/LocalStorageUtil";


import 'leaflet/dist/leaflet.css';
import './style.css'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DEFAULT_ZOOM = 17;
let DEFAULT_LOCATION = {coordinates: null, gps: 'UNDEFINED', zoom: DEFAULT_ZOOM, type: 'NO_LOCATION', address: null};
let _permissions = [];
const fpPromise = FingerprintJS.load();
(async () => {
    // Get the visitor identifier when you need it.
    const fp = await fpPromise;
    const result = await fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    const cid = localStorage.getItem('cid');
    if (cid) {
        // firebase
        let key = 'accounts/'+cid;
        firebase.database().ref(key).child('online').child(visitorId).set({date: new Date().getTime(), status: 'online'});
        firebase.database().ref(key).child('online').child(visitorId).onDisconnect().remove();
    }


})();

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const liff = window.liff;
const lineAuthentication = async(cb) => {
    console.log('lineAuthentication');
    const liffId = process.env.REACT_APP_LINE_LIFF_ID;
    try{
        await liff.init({ liffId: liffId, withLoginOnExternalBrowser: true }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            const idToken = await liff.getIDToken();
            const accessToken = await liff.getAccessToken();
            return cb({idToken: idToken, accessToken: accessToken});
        }else{
            // external browser
            console.log('no logged in');
            return cb(null);
        }
    }catch(e){}
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    header: {
        borderBottom: '1px solid #e7e7e7',
    },
    title: {
        flexGrow: 1,
        marginLeft: '10px',
    },
    menuButton: {
        padding: 0,
    },
    foodOrderBar: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#efefef'
    },
    content: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column'
    },
    footer: {
        borderTop: '1px solid #e7e7e7',
    },


});

function _distance(lat1,
                   lat2, lon1, lon2)
{

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 =  lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2),2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    return(c * r);
}

const PublishLayout = ({ children}) => {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const { historyData } = useLocation();
    const [customer, setCustomer] = useState({customerId: '', token: '', displayName: '', mobile: '', pictureUrl: ''});
    const [context, setContext] = useState({shop: {}, mode: 'VIEW', location: DEFAULT_LOCATION, openMap: false, success: false, fail: false, warning: false});
    const [anchorEl, setAnchorEl] = useState(null);
    const [accountName, setAccountName] = useState('');
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const [initial, setInitial ] = useState(0);
    const [openSetting, setOpenSetting] = useState(false);
    const [mode, setMode] = useState('VIEW');
    const [openMode, setOpenMode] = useState(false);

    console.log('[PublishLayout]');

    useEffect(() => {
        let token = localStorage.getItem('token');
        if(!token){
            let autoLogin = window.sessionStorage.getItem("autoLogin");
            if(autoLogin !== 'no'){
                lineAuthentication(lineToken=> {
                    console.log('lineAuthentication');
                    if(lineToken && cid){
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({accessToken: lineToken.accessToken, idToken: lineToken.idToken, redirectUri: process.env.REACT_APP_BASE_URL+'/login?cid='+cid, cid: cid})
                        };
                        const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
                        fetch(url, requestOptions)
                            .then(results => results.json())
                            .then(data => {
                                if(data.token){
                                    let decoded = jwt_decode(data.token);
                                    localStorage.setItem('token', data.token);
                                    setCustomer({customerId: decoded.customer, token: data.token, displayName: data.displayName, mobile: data.mobile, pictureUrl: data.pictureUrl});
                                    let customer = {
                                        displayName: data.displayName,
                                        pictureUrl: data.pictureUrl,
                                        mobile: data.mobile,
                                        address: data.address
                                    };
                                    localStorage.setItem('customer', JSON.stringify(customer));
                                } else {
                                    localStorage.removeItem('token');
                                }
                            });
                    }
                });
            }
        } else {
            let customer = JSON.parse(localStorage.getItem('customer'));
            if(customer){
                let decoded = jwt_decode(token);
                setCustomer({customerId: decoded.customer, token: token, displayName: customer.displayName, mobile: customer.mobile, pictureUrl: customer.pictureUrl});
            }

        }

        if(localStorage.getItem('customer') && cid){
            // update visitor
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/visit';
            httpClient.put(url, {cid: cid})
                .then(res => {
                    // done
                }).catch(e=>{});
        }


        localStorage.setItem('cid', cid);

        let cacheData = getCache(cid);
        // current account
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/location';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    if(res.data.delivery){
                        _permissions.push('DELIVERY');
                    }
                    if(res.data.pickup){
                        _permissions.push('PICKUP');
                    }
                    let mode = getMode();
                    if(mode === 'DELIVERY' && res.data.delivery){
                        saveMode({mode: 'DELIVERY', cid: cid, permissions: _permissions});
                        setMode(mode);
                    } else if(mode === 'PICKUP' && res.data.pickup){
                        saveMode({mode: 'PICKUP', cid: cid, permissions: _permissions});
                        setMode(mode);
                    } else {
                        if(res.data.delivery && res.data.pickup){
                            // select one
                            saveMode({mode: 'VIEW', cid: cid, permissions: _permissions});
                            setOpenMode(true);
                        } else if(res.data.delivery) {
                            // delivery
                            saveMode({mode: 'DELIVERY', cid: cid, permissions: _permissions});
                            setMode('DELIVERY');
                        } else if(res.data.pickup) {
                            // pickup
                            saveMode({mode: 'PICKUP', cid: cid, permissions: _permissions});
                            setMode('PICKUP');
                        } else {
                            // view only
                            saveMode({mode: 'VIEW', cid: cid, permissions: _permissions});
                            setMode('VIEW');
                        }
                    }
                    setInitial(1);
                    setAccountName(res.data.name.i18n.en);
                    let shop = {
                        lat: res.data.lat,
                        lng: res.data.lng,
                        distance: res.data.distance
                    };

                    let customer = JSON.parse(localStorage.getItem('customer'));

                    function _checkGeoLocation(permission){
                        let _contact = {myAddress: false};
                        if(customer){
                            _contact.contact = customer.displayName;
                            _contact.name = customer.displayName;
                            _contact.address = '';
                            if(customer.mobile){
                                _contact.mobile = customer.mobile;
                            }
                        }

                        if(!navigator.geolocation) {
                            console.log('Geolocation is not supported by your browser');
                            let newMyLocation = {contact: _contact, coordinates: null, gps: 'NOT_SUPPORT', zoom: DEFAULT_ZOOM, type: 'NO_LOCATION'};
                            let newCacheData = {...cacheData, date: new Date(), location: newMyLocation};
                            localStorage.setItem('cache', JSON.stringify(newCacheData));
                            let outOfDelivery = 2;
                            setContext({mode: mode, location:  newMyLocation, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false, warning: false});
                        } else {
                            if (permission.state === 'granted') {
                                navigator.geolocation.getCurrentPosition(result=>{
                                    const coordinates = result.coords;
                                    let newMyLocation = {contact: _contact, gps: 'ALLOW', zoom: DEFAULT_ZOOM, type: 'CURRENT', coordinates: {accuracy: coordinates.accuracy, lat: coordinates.latitude, lng: coordinates.longitude}};
                                    let newCacheData = {...cacheData, date: new Date(), location: newMyLocation};
                                    localStorage.setItem('cache', JSON.stringify(newCacheData));
                                    const cd = _distance(newMyLocation.coordinates.lat, shop.lat,
                                        newMyLocation.coordinates.lng, shop.lng);
                                    let outOfDelivery = 0;
                                    if(cd > shop.distance){
                                        outOfDelivery = 1;
                                    }
                                    setContext({mode: mode, location:  newMyLocation, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false, warning: false});
                                }, function(positionError) {
                                    console.log('positionError', positionError);
                                }, {enableHighAccuracy: true, maximumAge: 10000, timeout: 5000});
                            } else if (permission.state === 'prompt') {
                                try{
                                    navigator.geolocation.getCurrentPosition(result=>{
                                        const coordinates = result.coords;
                                        let newMyLocation = {contact: _contact, gps: 'ALLOW', zoom: DEFAULT_ZOOM, type: 'CURRENT', coordinates: {accuracy: coordinates.accuracy, lat: coordinates.latitude, lng: coordinates.longitude}};
                                        let newCacheData = {...cacheData, date: new Date(), location: newMyLocation};
                                        localStorage.setItem('cache', JSON.stringify(newCacheData));
                                        const cd = _distance(newMyLocation.coordinates.lat, shop.lat,
                                            newMyLocation.coordinates.lng, shop.lng);
                                        let outOfDelivery = 0;
                                        if(cd > shop.distance){
                                            outOfDelivery = 1;
                                        }
                                        setContext({mode: mode, location:  newMyLocation, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false, warning: false});
                                    }, function(positionError) {
                                        let newMyLocation = {contact: _contact, coordinates: null, gps: 'NOT_ALLOW', zoom: DEFAULT_ZOOM, type: 'NO_LOCATION'};
                                        let outOfDelivery = 2;
                                        setContext({mode: mode, location:  newMyLocation, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false, warning: false});
                                    }, {enableHighAccuracy: true, maximumAge: 10000, timeout: 5000});
                                } catch(err){
                                    let newMyLocation = {contact: _contact, coordinates: null, gps: 'NOT_ALLOW', zoom: DEFAULT_ZOOM, type: 'NO_LOCATION'};
                                    let outOfDelivery = 2;
                                    setContext({mode: mode, location:  newMyLocation, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false, warning: false});
                                }
                            } else {
                                let newMyLocation = {contact: _contact, coordinates: null, gps: 'NOT_ALLOW', zoom: DEFAULT_ZOOM, type: 'NO_LOCATION'};
                                let outOfDelivery = 2;
                                setContext({mode: mode, location:  newMyLocation, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false, warning: false});
                            }
                        }
                    }

                    if(!cacheData.location){
                        try{
                            navigator.permissions.query({name:'geolocation'}).then(function(result) {
                                // check permission
                                _checkGeoLocation(result);
                            });
                        } catch(err){
                            _checkGeoLocation({state: 'prompt'});
                        }

                    } else {
                        if(customer){
                            if(!cacheData.location.contact.contact){
                                let _contact = {myAddress: false};
                                _contact.contact = customer.displayName;
                                _contact.name = customer.displayName;
                                _contact.address = '';
                                if(customer.mobile){
                                    _contact.mobile = customer.mobile;
                                }
                                cacheData.location.contact = _contact;
                                localStorage.setItem('cache', JSON.stringify(cacheData));
                            }
                        }
                        const cd = _distance(cacheData.location.coordinates.lat, shop.lat,
                            cacheData.location.coordinates.lng, shop.lng);
                        let outOfDelivery = 0;
                        if(cd > shop.distance){
                            outOfDelivery = 1;
                        }
                        setContext({mode: mode, location:  cacheData.location, outOfDelivery: outOfDelivery, shop: shop, openMap: false, success: false, fail: false, warning: false});
                    }

                } else {
                    // invalid cid
                    setInitial(2);
                }
            }).catch(e=>{
                console.log(e);
                setInitial(2);
            });

    }, [cid, historyData]);

    const handleLogin = (event) => {
        const clientId = process.env.REACT_APP_LINE_CLIENT_ID;
        const redirectUri = `${process.env.REACT_APP_BASE_URL}/login?cid=${cid}`;
        const state = Math.floor(100000 + Math.random() * 900000);

        const url =
            'https://access.line.me/oauth2/v2.1/authorize?response_type=code' +
            '&client_id=' +
            clientId +
            '&redirect_uri=' +
            redirectUri +
            '&state=' +
            state +
            '&scope=profile';

        window.location.href = url;
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setContext({ ...context, openMap: false, success: false, fail: false, warning: false });
    };

    const handleMyProfile = () => {
        handleMenuClose();
        history.push('/p');
    };

    const handleMyOrderHistory = () => {
        handleMenuClose();
        let _cid = cid || localStorage.getItem('cid');
        history.push(`/h/${_cid}`);
    };

    const handleSetting = (value) => {
        handleMenuClose();
        setOpenSetting(value);
    };

    const handleSelectMode = (value) => {
        saveMode({mode: value, cid: cid, permissions: _permissions});
        setOpenMode(false);
        setMode(value);
        setInitial(1);
        setContext({...context, mode: value});
    };

    const handleLogout = () => {
        localStorage.clear();
        window.sessionStorage.setItem("autoLogin", "no");
        window.location.href = `/m/${cid}`;
    };

    return (
        <div>
            <CssBaseline />
            {initial === 0 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {initial === 1 &&
                <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{accountName}</title>
                    </Helmet>
                    <OrdersTracking customerId={customer.customerId} />
                    {(context.outOfDelivery === 1 && context.mode === 'DELIVERY') &&
                    <Box pb={0}>
                        <Alert variant="filled" elevation={0} severity="warning" style={{borderRadius: 0}}>{t('common.message.outOfDelivery')}</Alert>
                    </Box>
                    }
                    {context.outOfDelivery === 2 &&
                    <Box pb={0}>
                        <LocationAlert/>
                    </Box>
                    }
                    <div>
                        <header className={classes.header}>
                            <AppBar elevation={0} position="static" color="light">
                                <Toolbar>
                                    <Typography variant="body1" component="div" className={classes.title}>
                                        <ModeSelection mode={mode} exit={true} onSelect={handleSelectMode} />
                                    </Typography>

                                    {!customer.token &&
                                    <IconButton edge="end"
                                                onClick={handleLogin}
                                                color="inherit" aria-label="menu">
                                        <AccountCircleIcon fontSize="large"/>
                                    </IconButton>
                                    }

                                    {customer.token &&
                                    <IconButton edge="end"
                                                onClick={handleMenu}
                                                color="inherit" aria-label="menu">
                                        <Avatar alt="profile" src={customer.pictureUrl}/>
                                    </IconButton>
                                    }

                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem dense onClick={(e) => handleMyProfile()}>{t('profile.myInformation')}</MenuItem>
                                        <MenuItem dense onClick={(e) => handleMyOrderHistory()}>{t('profile.ordersHistory')}</MenuItem>
                                        <MenuItem dense onClick={(e) => handleSetting(true)}>{t('setting.changeLanguage')}</MenuItem>
                                        <Divider sx={{my: 0.5}}/>
                                        <MenuItem dense onClick={(e) => handleLogout()}>{t('logout')}</MenuItem>
                                    </Menu>
                                </Toolbar>
                            </AppBar>
                        </header>
                        <div className={classes.content}>
                            <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
                        </div>
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                    </>
            }
            {initial === 2 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <Alert variant="filled" elevation={0} severity="warning">
                    {t('common.message.invalidCid')}
                </Alert>
            </Box>
            }
            <SettingDialog open={openSetting} onClose={(e) => handleSetting(false)} />
            <ModeDialog open={openMode} onSelect={handleSelectMode} />
            <Snackbar open={context.success}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert variant="filled" onClose={handleClose} severity="success">
                    This is a success message!
                </Alert>
            </Snackbar>
            <Snackbar open={context.fail}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert variant="filled" onClose={handleClose} severity="error">
                    This is a error message!
                </Alert>
            </Snackbar>
            <Snackbar open={context.warning}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert variant="filled" onClose={handleClose} severity="error">
                    {t('address.notAllowGPS')}
                </Alert>
            </Snackbar>
        </div>
    );
};

const PublishLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                return <PublishLayout><Component {...props} /></PublishLayout>
            }}
        />
    );
};

export default PublishLayoutRoute;