import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import locale_th from 'dayjs/locale/th';
dayjs.extend(relativeTime);
dayjs.extend(weekOfYear);

export function DateTime(props) {
    const { i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0] || 'en';
    let [display, setDisplay] = useState('');
    useEffect(() => {
        let format = props.format || 'DD MM YYYY';
        if(currentLang === 'th'){
            let newValue = dayjs(props.value).locale(locale_th).format(format);
            setDisplay(newValue);
        } else {
            let newValue = dayjs(props.value).format(format);
            setDisplay(newValue);
        }

    }, [props, currentLang, setDisplay]);

    return(
        <>
        {props.value &&
            <span>
                {display}
            </span>
        }
        </>
    )
}