import React from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import StoreIcon from '@mui/icons-material/Store';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import {getModePermissions} from "../../core/LocalStorageUtil";

export default function ModeDialog({open, onClose, onSelect}) {
    const { t } = useTranslation();
    const permissions = getModePermissions() || [];
    console.log('[ModeDialog]');

    return (
        <Dialog open={open}
                onClose={onClose}
                fullWidth={true}>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box>
                            <Button variant="outlined"
                                    color="primary"
                                    size="large"
                                    onClick={e=>onSelect('PICKUP')}
                                    fullWidth={true}
                                    disabled={permissions.indexOf('PICKUP') < 0}
                                    startIcon={<StoreIcon />}>
                                    {t('deliver.pickup')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box>
                            <Button variant="outlined"
                                    color="primary"
                                    size="large"
                                    onClick={e=>onSelect('DELIVERY')}
                                    fullWidth={true}
                                    disabled={permissions.indexOf('DELIVERY') < 0}
                                    startIcon={<TwoWheelerIcon />}>
                                {t('deliver.delivery')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
};