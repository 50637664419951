import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FoodOrderTimeLine from '../../FoodOrder/FoodOrderTimeLine';
import {httpClient} from "../../../core/HttpClient";

export default function OrdersInfo() {
    const { cid, uid } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);

    useEffect(() => {
        console.log('OrdersInfo');
        let url = process.env.REACT_APP_API_BASE_URL + `/secure/customer/order/${uid}`;
        httpClient.get(url)
            .then(res => {
                if(res.data && res.status ===  200){
                    setItems([res.data])
                }
                setLoading(false);
            }).catch(e=>{
                let _cid = cid || localStorage.getItem('cid');
                history.push(`/m/${_cid}`);
            });
    }, [cid, uid, history]);

    return (
        <Box>
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <FoodOrderTimeLine items={items}/>
            }
        </Box>
    );
}
