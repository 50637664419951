import React from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';

export default function LocationAlert() {
    const { t } = useTranslation();

    return (
        <div>
            <Alert variant="filled" severity="warning" style={{borderRadius: 0}}>{t('address.alert')}</Alert>
        </div>
    );
}
