import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import RecommendIcon from '@mui/icons-material/Recommend';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import {TextI18n, getTextI18n} from "../../TextI18n";
import {TextI18nTruncate} from "../../TextI18nTruncate";

export function ProductViewOnly({product}) {
    const { t } = useTranslation();
    console.log('[ProductViewOnly]');

    return(
        <div>
            <Box pb={12}>
                <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box mr={1}>
                        <Typography component={'span'} variant="h6">
                            <TextI18nTruncate value={product.name} lines={2}></TextI18nTruncate>
                        </Typography>
                        </Box>
                        {product.recommend &&
                        <Box display="flex" alignItems="center">
                            <RecommendIcon color="error" fontSize="small" />
                            <Typography color="error"  variant="caption" style={{marginLeft: '0.1rem'}}>
                                {t('product.recommend')}
                            </Typography>
                        </Box>
                        }
                    </Box>
                    <Box>
                        <Typography component={'span'} variant="subtitle1" color="textSecondary">
                            <TextI18nTruncate value={product.description} lines={2}></TextI18nTruncate>
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         style={{width: '100%'}}>
                        <div>{t('product.price')}</div>
                        <div><NumberFormat value={product.price} displayType={'text'}
                                           thousandSeparator={true} prefix={'฿ '}/></div>
                    </Box>
                    <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    <Box pb={2}>
                        <Typography variant="body1">
                            <TextI18n value={product.detail}></TextI18n>
                        </Typography>
                    </Box>
                    <Box>
                        {product.images.map((image, i) => (
                            <img key={i}
                                 style={{width: '100%', marginTop: '8px'}}
                                 src={`${process.env.REACT_APP_CDN_BASE_URL}/${image.permission}/o/${image.name}`}
                                 alt={getTextI18n(product.name)}
                            />
                        ))
                        }
                    </Box>
                </CardContent>
            </Box>
        </div>
    )
}