import React, { useState, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function SettingDialog({open, onClose}) {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
    console.log('[SettingDialog]');

    const handleSave = () => {
        i18n.changeLanguage(currentLanguage);
        onClose();
    };

    const handleChange = ({ target }) => {
        setCurrentLanguage(target.value);
    };

    return (
        <Dialog open={open}
                onClose={onClose}
                fullWidth={true}
                TransitionComponent={Transition}>
            <AppBar elevation={0} position="static" color="light" sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        {t('setting.changeLanguage')}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                <FormControl variant="outlined"
                             fullWidth={true}>
                    <InputLabel id="language-select-filled-label">{t('setting.language')}</InputLabel>
                    <Select
                        native
                        labelId="language-select-filled-label"
                        label={t('setting.language')}
                        name="role"
                        onChange={handleChange}
                        value={currentLanguage}>
                        <option value="en-US">{t('languages.english')}</option>
                        <option value="th-TH">{t('languages.thai')}</option>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" fullWidth={true} size="large" disableElevation onClick={handleSave}>
                    {t('common.save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
};