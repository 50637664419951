import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import FoodOrderDialog from '../../components/FoodOrder/FoodOrderDialog'
import firebase from "../../core/Firebase";
import {httpClient} from "../../core/HttpClient";

let searching = false;


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        marginLeft: '10px',
    },


});

export default function OrdersTracking({customerId}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [list, setList] = useState([]);
    const [openFoodOrder, setOpenFoodOrder ] = useState(false);
    console.log('[OrdersTracking]');
    useEffect(() => {
        let firebaseKey = 'customers/'+customerId;
        let customerRef = firebase.database().ref(firebaseKey);
        let listener = customerRef.on('value', function(data) {
            if(data){
                let fo = data.val();
                if(fo){
                    let list = [];
                    if(!searching){
                        searching = true;
                        let url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/orders?d=true';
                        httpClient.get(url)
                            .then(res => {
                                searching = false;
                                if(res.data){
                                    if(Array.isArray(res.data)){
                                        res.data.forEach(d=>{
                                            d.account = d._account;
                                            list.push(d);
                                        });
                                    }
                                    setList(list);
                                }else{
                                    setList([]);
                                }
                            });
                    }
                }
            }
        });

        return () => customerRef.off('value', listener);
    }, [customerId]);

    const handleFoodOrderOpen = () => {
        setOpenFoodOrder(true);
    };

    const handleFoodOrderClose = () => {
        setOpenFoodOrder(false);
    };

    return (
        <div>
            {list.length > 0 &&
            <div style={{height: '3.5rem'}}>
                <Box
                    style={{
                        width: '100%',
                        cursor: 'pointer',
                        top: 0,
                        zIndex: 999,
                        position: 'fixed'
                    }}
                >
                    <AppBar elevation={0} position="sticky" color="primary"
                            onClick={handleFoodOrderOpen}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                            >
                                <ExpandMoreIcon/>
                            </IconButton>
                            <Typography className={classes.title} variant="body" noWrap>
                                <CardHeader
                                    style={{padding: '3px', fontWeight: 'bold'}}
                                    title={list[0].orderNo}
                                    titleTypographyProps={{variant:'title' }}
                                    subheader={list[0].timeLine.step===1?t('order.orders'):list[0].timeLine.step===2?t('order.cooking'):list[0].timeLine.step===3?t('order.delivering'):list[0].timeLine.step===4?t('order.received'):''}
                                    subheaderTypographyProps={{variant:'subtitle2', color: 'white'}}
                                />
                            </Typography>
                            {list[0].timeLine.step===1 &&
                            <AssignmentTurnedInIcon />
                            }
                            {list[0].timeLine.step===2 &&
                            <FastfoodIcon />
                            }
                            {list[0].timeLine.step===3 &&
                            <TwoWheelerIcon />
                            }
                            {list[0].timeLine.step===4 &&
                            <SentimentVerySatisfiedIcon />
                            }
                        </Toolbar>
                    </AppBar>
                </Box>
            </div>
            }
            <FoodOrderDialog open={openFoodOrder} items={list} onClose={handleFoodOrderClose} />
        </div>
    );
}