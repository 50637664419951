import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


export default function FoodOrderPayment({value}) {
    const { t } = useTranslation();

    return (
        <Box m={1} mt={2} style={{textAlign: 'center'}}>
            {value.payment &&
            <Typography variant="body1">
                ** {t(`payment.${value.payment.method}Description`)} **
            </Typography>
            }
            {value.payment && value.payment.attachment &&
            <img
                style={{width: '100%'}}
                alt={value.payment.attachment.originalName}
                src={`https://cdn.foodkub.com/${value.payment.attachment.key}`}
            />
            }
        </Box>
    );
}
