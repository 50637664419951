import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import StoreIcon from '@mui/icons-material/Store';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ModeDialog from '../../components/ModeDialog'

export default function ModeSelection({mode, exit, permissions, onSelect}) {
    const { t } = useTranslation();
    const [openMode, setOpenMode] = useState(false);
    console.log('[ModeSelection]');

    const handleSelect = () => {
        setOpenMode(true);
    };

    const handleClose = () => {
        if(exit){
            setOpenMode(false);
        }
    };

    const handleSelectMode = (value) => {
        setOpenMode(false);
        onSelect(value);
    };

    return (
        <Box>
            {mode === 'PICKUP' &&
            <Box display="flex" alignItems="center">
                <StoreIcon onClick={handleSelect} />
                <Box ml={1} onClick={handleSelect}>{t('deliver.pickup')}</Box>
            </Box>
            }
            {mode === 'DELIVERY' &&
            <Box display="flex" alignItems="center">
                <TwoWheelerIcon onClick={handleSelect} />
                <Box ml={1} onClick={handleSelect}>{t('deliver.delivery')}</Box>
            </Box>
            }
            {mode === 'VIEW' &&
            <Box display="flex" alignItems="center">
                <MenuBookIcon />
                <Box ml={1}>{t('deliver.menuOnline')}</Box>
            </Box>
            }
            <ModeDialog open={openMode} permissions={permissions} onClose={handleClose} onSelect={handleSelectMode} />
        </Box>
    )
};