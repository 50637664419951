import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import { withStyles } from "@mui/styles";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ProductViewDialog from "../../Product/ProductControlDialog";
import {httpClient} from "../../../core/HttpClient";
import {TextI18nTruncate} from "../../TextI18nTruncate";
import CartUtil from "../../../core/CartUtil";
import {getCache} from "../../../core/LocalStorageUtil";
import {Context} from "../../../core/Context";
import {getTextI18n} from "../../TextI18n";

const shapeStyles = { border: '1px solid #a3a3a3', fontSize: '0.85rem' };
// const shapeInvertStyles = { backgroundColor: '#fff', padding: '0 8px 0 8px', color: '#5565c0', fontSize: '1 rem' };

const SelectedBox = (({value}) => {
    return (
        <Box sx={{textAlign: 'center', paddingRight: '15px'}}>
            <div style={shapeStyles}>{value}</div>
        </Box>
    )
});

const itemInfoBox = (item, product, t) => {
    let optionalInfo = '';
    if(item.optional){
        Object.keys(item.optional).forEach(key=>{
            if(key !== 'total'){
                if(item.optional[key].checkedName){
                    item.optional[key].checkedName.forEach(checkedName=>{
                        if(checkedName){
                            if(optionalInfo !== ''){
                                optionalInfo += ', ';
                            }
                            optionalInfo += getTextI18n(checkedName);
                        }

                    })
                }

            }
        });
    }

    if(item.additional){
        if(optionalInfo !== ''){
            optionalInfo += ', ';
        }
        optionalInfo += item.additional;
    }

    return (
        <Box sx={{ color: 'text.secondary' }}>
            <span>{optionalInfo?optionalInfo:t('cart.noAdditional')}</span>
        </Box>
    )
};

const MyListItem = withStyles({
    secondaryAction: {
        paddingRight: 100
    }
})(ListItem);

const MyProductItem = (({product, i, t, onClick}) => {
    return (
        <>
            {product &&
                product.items.map((item, j) => (
                    <MyListItem button divider={true} key={`key-${i}-${j}`} onClick={e=>onClick(product, product.items, {...item, index: j})} >
                        <ListItemAvatar sx={{minWidth: '48px'}}>
                            <SelectedBox value={item.qty}></SelectedBox>
                        </ListItemAvatar>
                        <ListItemText style={{wordBreak: 'break-word', paddingRight: '1.5rem'}}>
                            <Typography component={'span'} variant="body1">
                                <TextI18nTruncate value={product.name} lines={2}></TextI18nTruncate>
                            </Typography>
                            {itemInfoBox(item, product, t)}
                            <div>
                                <Link sx={{fontSize: '0.85rem'}}>{t('common.edit')}</Link>
                            </div>
                        </ListItemText>
                        <ListItemSecondaryAction>
                            <Typography variant="subtitle2" sx={{'marginLeft': '10px'}}>
                                <NumberFormat value={(product.price + item.optional.total) * item.qty} displayType={'text'} thousandSeparator={true} prefix={'฿ '} />
                            </Typography>
                        </ListItemSecondaryAction>
                    </MyListItem>
                ))
            }
        </>
    )
});

function _distance(lat1,
                   lat2, lon1, lon2)
{

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 =  lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2),2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    return(c * r);
}

export default function CartList({shop, delivery, mode}) {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [cart, setCart] = useState({products: []});
    const [product, setProduct] = useState({});
    const [item, setItem] = useState({});
    const [open, setOpen] = useState({loading: false, dialog: false});

    const loadCart = useCallback((callback) => {

        let myCart = {outOfDelivery: 0, total: 0, qty:0, products: {}};
        let cacheData = getCache(cid);
        if(!cacheData.cart){
            return history.push({
                pathname: `/m/${cid}`,
                historyData: {showFoodOrder: false}
            });
        }

        if(cacheData.cart.qty === 0){
            history.push({
                pathname: `/m/${cid}`,
                historyData: {showFoodOrder: false}
            });
        }else {
            myCart = cacheData.cart;
        }

        if(cacheData.location){
            const cd = _distance(cacheData.location.coordinates.lat, shop.lat,
                cacheData.location.coordinates.lng, shop.lng);
            let outOfDelivery = 0;
            if(cd > shop.distance){
                outOfDelivery = 1;
            }
            myCart.outOfDelivery = outOfDelivery;

            let distance = Math.floor(cd * 10) / 10;
            let deliveryPrices = shop.deliveryPrices;
            let d = Math.floor(distance);
            let ds = Math.floor((distance - d) * 10) / 10;
            let price = shop.deliveryStartPrice || 0;
            if(deliveryPrices){
                for (let i = 0; i < d; i++) {
                    if(deliveryPrices[i]){
                        price += deliveryPrices[i];
                    }
                }
                if(ds > 0){
                    if(deliveryPrices[d]){
                        price += Math.floor(deliveryPrices[d] * ds);
                    }
                }
            }
            delivery.distance = distance;
            delivery.price = price;
            myCart.deliveryPrice = price;
        }

        let keys = Object.keys(myCart.products);
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/products/list';
        let data = {
            productIds: keys
        };

        httpClient.post(url, data)
            .then(res => {
                if(res.data){
                    let products = [];
                    let newCart = {
                        qty: myCart.qty,
                        total: myCart.total
                    };
                    res.data.forEach(p=>{
                        let cp = myCart.products[p._id];
                        products.push({_id: p._id, name: p.name, description: p.description, detail: p.detail, images: p.images, items: cp.items, qty: cp.qty, price: p.price, options: p.options, remaining: p.remaining});
                    });
                    newCart.products = products;
                    setCart(newCart);
                }

                callback(myCart);
            }).catch(e=>{
                callback();
            })

    }, [shop, delivery, cid, history]);

    useEffect(() => {
        console.log('CartList');
        loadCart(r=>{
            if(r){
                setContext({mode: mode, outOfDelivery: r.outOfDelivery, paymentAmount: r.total, deliveryPrice: r.deliveryPrice});
            }
        });
    }, [loadCart,mode, setContext]);



    const handleClickItem = (product,productItems, item)=>{
        let cartQty = 0;
        if(productItems){
            productItems.forEach((mi,i)=>{
                if(item.index !== i){
                    cartQty+=mi.qty;
                }
            })
        }
        setProduct({...product, cartQty: cartQty});
        setItem(item);
        setOpen({loading: false, dialog: true});
    };

    const handleChange = (value) => {
        if(value.mode === 0){
            // update
            new CartUtil(cid).updateCart(value.product, value.item);
        } else if(value.mode === 1){
            // add
            new CartUtil(cid).addToCart(value.product, value.item);
        } else if(value.mode === -1){
            // remove
            new CartUtil(cid).removeCartItem(value.product, value.item);
        }

        loadCart(r=>{
            setOpen({loading: false, dialog: false});
            if(r){
                setContext({...context, paymentAmount: r.total, deliveryPrice: r.deliveryPrice});
            }
        });
    };

    const handleClose = () => {
        setOpen({loading: false, dialog: false});
    };

    const handleAddMoreItem = () => {
        history.push(`/m/${cid}`);
    };

    return (
        <div>
            <Card sx={{borderRadius: '0px'}} pt={2}>
                <CardHeader style={{paddingBottom: '5px'}} title={<Typography variant="h6" component="div">
                    {t('cart.myOrder')}
                </Typography>} />
                {cart.products.length > 0 &&
                <List style={{paddingTop: 0, paddingBottom: 0}}>
                    {cart.products.map((product, i) => (
                        <MyProductItem key={i} product={product} i={i} t={t} onClick={handleClickItem} />
                    ))}
                </List>
                }
                <CardContent>
                    <Box mb={2}>
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            disableElevation
                            fullWidth={true}
                            onClick={handleAddMoreItem} >
                            {t('cart.addAnother')}
                        </Button>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         style={{width: '100%'}}>
                        <div>{t('cart.food')}</div>
                        <div><NumberFormat value={cart.total} displayType={'text'}
                                           thousandSeparator={true} prefix={'฿ '}/></div>
                    </Box>
                    <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         style={{width: '100%'}}>
                        <div>
                            <span>{t('cart.delivery')}</span>
                            <Typography variant="body1" color="textSecondary" sx={{fontSize: '0.85rem'}}>{t('cart.distance')} {delivery.distance} {t('cart.distanceUnit')}</Typography>
                        </div>
                        <div><NumberFormat value={delivery.price} displayType={'text'}
                                           thousandSeparator={true} prefix={'฿ '}/></div>
                    </Box>
                    <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         fontWeight="fontWeightBold"
                         style={{width: '100%'}}>
                        <div>{t('common.total')}</div>
                        <div><NumberFormat value={cart.total + delivery.price} displayType={'text'}
                                           thousandSeparator={true} prefix={'฿ '}/></div>
                    </Box>
                </CardContent>
            </Card>
            <ProductViewDialog
                shop={shop}
                product={product}
                item={item}
                open={open.dialog}
                onChange={handleChange}
                onClose={handleClose}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open.loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </div>
    );
}
