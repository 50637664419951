import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const liff = window.liff;
export default function LandingPage(props) {
    const history = useHistory();

    console.log('LandingPage');

    useEffect(() => {
        try {
            const liffId = process.env.REACT_APP_LINE_LIFF_ID;
            liff.init({liffId: liffId, withLoginOnExternalBrowser: true}).catch(err => {
                throw err
            });
        } catch (e){
            console.log(e);
        }
    }, [history]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
        </div>
    );
}
