import React from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import DayJS from 'react-dayjs';
import NumberFormat from 'react-number-format';
import DeliveryView from "../../Location/DeliveryView";
import RiderInfo from "../../Rider/RiderInfo";
import FoodOrderItems from '../FoodOrderItems';
import {getTextI18n} from "../../TextI18n";
import {DateTime} from "../../DateTime";

const useStyles = makeStyles({
    root: {},
    cardHeader: {
        borderBottom: '1px solid #a8a8a8'
    }
});

export default function FoodOrderTimeLine({items}) {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    console.log('[FoodOrderTimeLine]');

    const handleBlack = (cid) => {
        if(cid){
            history.push(`/m/${cid}`);
        }
    };

    const CardOrder = ({item}) =>(
        <Box mb={1}>
            <>
                <Box>
                    {item.status === 'cancel' &&
                    <Box mb={2} p={1}>
                        <Alert severity="error">{t('order.canceled')}</Alert>
                    </Box>
                    }
                    {item.status === 'received' &&
                    <Box mb={2} p={1}>
                        <Alert severity="success">{t('order.customerReceived')}</Alert>
                    </Box>
                    }
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <List className={classes.root}>
                                <ListItem>
                                    <ListItemAvatar onClick={e=>handleBlack(item.account.cid)}>
                                        <Avatar aria-label="picture" src={
                                            item.account.image?
                                                `${process.env.REACT_APP_CDN_BASE_URL}/public/s/${item.account.image.name}`: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
                                        }>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={getTextI18n(item.account.name)} secondary={<>{t('shop.tel')}<a href={`tel:${item.account.phone}`}><NumberFormat value={item.account.phone} displayType={'text'} format="###-###-####" /></a></>} />
                                </ListItem>
                            </List>
                        </Card>
                        <Box mt={1}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="body1">
                                            {t('order.number')}
                                        </Typography>
                                        <Typography variant="body1">
                                            {item.orderNo}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography variant="body1">
                                            {t('order.date')}
                                        </Typography>
                                        <Typography variant="body1">
                                            <DateTime value={item.orderDate}
                                                      format="DD MMM YYYY HH:mm"></DateTime>
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Box display="flex" justifyContent="center">
                            <FoodOrderItems value={item}></FoodOrderItems>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <DeliveryView shop={item.account} location={item.location} />
                    </Grid>
                    {item.status !== 'cancel' && item.rider &&
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Box display="flex" justifyContent="center">
                                    <Typography variant="h6" component="div">
                                        {t('rider.info')}
                                    </Typography>
                                </Box>
                                <RiderInfo rider={item.rider} />
                            </CardContent>
                        </Card>
                    </Grid>
                    }
                    {item.status !== 'cancel' &&
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Box display="flex" justifyContent="center">
                                    <Typography variant="h6" component="div">
                                        {t('order.state')}
                                    </Typography>
                                </Box>
                                <Timeline position="alternate">
                                    <TimelineItem>
                                        {item.timeLine.orders &&
                                        <TimelineOppositeContent
                                            color={item.timeLine.step === 1 ? "text.primary" : "text.secondary"}>
                                            <DayJS format="HH:mm">{item.timeLine.orders}</DayJS>
                                        </TimelineOppositeContent>
                                        }
                                        <TimelineSeparator>
                                            <TimelineDot color="primary"><AssignmentTurnedInIcon/></TimelineDot>
                                            <TimelineConnector style={{height: '22px'}}/>
                                        </TimelineSeparator>
                                        <TimelineContent
                                            color={item.timeLine.step === 1 ? "text.primary" : "text.secondary"}>{t('order.orders')}</TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                        {item.timeLine.cooking &&
                                        <TimelineOppositeContent
                                            color={item.timeLine.step === 2 ? "text.primary" : "text.secondary"}>
                                            <DayJS format="HH:mm">{item.timeLine.cooking}</DayJS>
                                        </TimelineOppositeContent>
                                        }
                                        <TimelineSeparator>
                                            <TimelineDot
                                                color={item.timeLine.cooking ? "primary" : "grey"}><FastfoodIcon/></TimelineDot>
                                            <TimelineConnector style={{height: '22px'}}/>
                                        </TimelineSeparator>
                                        <TimelineContent
                                            color={item.timeLine.step === 2 ? "text.primary" : "text.secondary"}>{t('order.cooking')}</TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                        {item.timeLine.delivering &&
                                        <TimelineOppositeContent
                                            color={item.timeLine.step === 3 ? "text.primary" : "text.secondary"}>
                                            <DayJS format="HH:mm">{item.timeLine.delivering}</DayJS>
                                        </TimelineOppositeContent>
                                        }
                                        <TimelineSeparator>
                                            <TimelineDot
                                                color={item.timeLine.delivering ? "primary" : "grey"}><TwoWheelerIcon/></TimelineDot>
                                            <TimelineConnector style={{height: '22px'}}/>
                                        </TimelineSeparator>
                                        <TimelineContent
                                            color={item.timeLine.step === 3 ? "text.primary" : "text.secondary"}>{t('order.delivering')}</TimelineContent>
                                    </TimelineItem>
                                    <TimelineItem>
                                        {item.timeLine.received &&
                                        <TimelineOppositeContent
                                            color={item.timeLine.step === 4 ? "text.primary" : "text.secondary"}>
                                            <DayJS format="HH:mm">{item.timeLine.received}</DayJS>
                                        </TimelineOppositeContent>
                                        }
                                        <TimelineSeparator>
                                            <TimelineDot
                                                color={item.timeLine.received ? "primary" : "grey"}><SentimentVerySatisfiedIcon/></TimelineDot>
                                        </TimelineSeparator>
                                        <TimelineContent
                                            color={item.timeLine.step === 4 ? "text.primary" : "text.secondary"}>{t('order.received')}</TimelineContent>
                                    </TimelineItem>
                                </Timeline>
                            </CardContent>
                        </Card>
                    </Grid>
                    }
                </Grid>
            </>
        </Box>
    );

    return (
        <Box>
            {items.map(item => (
                <Box key={item.orderNo} mb={2}>
                    <CardOrder item={item} />
                </Box>
            ))}
            {items.length === 0 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="body1" color="textSecondary"
                            sx={{fontSize: '0.85rem'}}>{t('common.notFound')}</Typography>
            </Box>
            }
        </Box>
    )
};