import React from 'react';
// import { useTranslation } from "react-i18next";

export function getTextI18n(data) {
    if(data && data.i18n){
        return data.i18n['th'] || data.i18n['en'];
    } else {
        return '';
    }
}

export function TextI18n(props) {
    // const { i18n } = useTranslation();
    // const currentLang = i18n.language.split('-')[0] || props.value.i18n['en'];
    return(
        <span style={{wordWrap: 'break-word'}}>
            {props.value &&
                props.value.i18n['en']
            }
        </span>
    )
}