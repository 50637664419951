import React, { forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import CardHeader from '@mui/material/CardHeader';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Slide from '@mui/material/Slide';
import FoodOrderTimeLine from '../FoodOrderTimeLine';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



export default function FoodOrderDialog({open, items, onClose}) {
    console.log('[FoodOrderDialog]');
    const { t } = useTranslation();

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}>
            <AppBar sx={{ top: 'auto', bottom: 0 }} color="primary" >
                <Toolbar
                    onClick={onClose}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                    >
                        <ExpandLessIcon/>
                    </IconButton>
                    {items[0] &&
                    <>
                        <Typography style={{
                            flexGrow: 1
                        }} variant="body" noWrap>
                            <CardHeader
                                style={{padding: '3px', fontWeight: 'bold'}}
                                title={items[0].orderNo}
                                titleTypographyProps={{variant:'title' }}
                                subheader={items[0].timeLine.step===1?t('order.orders'):items[0].timeLine.step===2?t('order.cooking'):items[0].timeLine.step===3?t('order.delivering'):items[0].timeLine.step===4?t('order.received'):''}
                                subheaderTypographyProps={{variant:'subtitle2', color: 'white'}}
                            />
                        </Typography>
                        {items[0].timeLine.step===1 &&
                        <AssignmentTurnedInIcon />
                        }
                        {items[0].timeLine.step===2 &&
                        <FastfoodIcon />
                        }
                        {items[0].timeLine.step===3 &&
                        <TwoWheelerIcon />
                        }
                        {items[0].timeLine.step===4 &&
                        <SentimentVerySatisfiedIcon />
                        }
                    </>
                    }
                    {!items[0] &&
                    <Typography style={{
                        flexGrow: 1
                    }} variant="body" noWrap>
                        {t('order.deliveryOrder')}
                    </Typography>
                    }
                </Toolbar>
            </AppBar>
            <Box mb={5}>
                <FoodOrderTimeLine items={items} />
            </Box>
        </Dialog>
    )
};