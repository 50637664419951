import React from 'react';
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import NumberFormat from 'react-number-format';

export default function RiderInfo({rider}) {
    const { t } = useTranslation();

    return (
        <div>
            {rider &&
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar aria-label="picture" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${rider._id}`}>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={rider.displayName} secondary={rider.mobile && <>{t('shop.tel')}<a href={`tel:${rider.mobile}`}><NumberFormat value={rider.mobile} displayType={'text'} format="###-###-####" /></a></>} />
                </ListItem>
            </List>
            }
        </div>
    );
}
