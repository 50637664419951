import React, { useState, useRef, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Sticky from 'react-sticky-el';
import NumberFormat from 'react-number-format';
import {isDesktop} from 'react-device-detect';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Circle, useMapEvents } from 'react-leaflet'
import {httpClient} from "../../../core/HttpClient";

import 'leaflet/dist/leaflet.css';
import './style.css'

const markerIcon = new L.Icon({
    iconUrl: 'https://cdn.foodkub.com/public/assets/fkmark.png',
    iconRetinaUrl: 'https://cdn.foodkub.com/public/assets/fkmark2.png',
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 44],
    iconAnchor: [15, 44],
    className: 'leaflet-div-icon'
});

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

let tempLatLng = null;
let currentTempLatLng = null;
let locationChanged = true;
let selectAddress = false;

const blueIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|237cc9&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    }),
    redIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

export { markerIcon };

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LocationMarker = (props) => {
    const [icon, setIcon] = useState(redIcon);
    const [position, setPosition] = useState(props.position);
    const markerRef = useRef(null);
    const map = useMapEvents({
        move() {
            const marker = markerRef.current;
            if (marker != null) {
                marker.setLatLng(map.getCenter());
            }
        },
        moveend(e) {
            let center = map.getCenter();
            if(tempLatLng){
                if((tempLatLng[0] !== center.lat || tempLatLng[1] !== center.lng) && locationChanged){
                    setIcon(blueIcon);
                } else {
                    setIcon(redIcon);
                }
                setPosition([center.lat, center.lng]);
            } else {
                setIcon(blueIcon);
                setPosition([center.lat, center.lng]);
            }
            if(locationChanged){
                selectAddress = false;
            }
            locationChanged = true;
        }
    });

    return position === null ? null : (
        <Marker position={position} ref={markerRef} icon={icon} ></Marker>
    )
};

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: '#fff',
        padding: '10px',
    },
});

export default function AddressDialog({open, location, shop, addressList, onChange, onClose, onAddressChange}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState({addNewAddress: false});
    const [address, setAddress] = useState({_id: '', addressName: '', name: '', mobile: '', address: ''});
    const [contact, setContact] = useState(location.contact);
    const [position, setPosition] = useState(location.coordinates?[location.coordinates.lat, location.coordinates.lng]:[shop.lat, shop.lng]);
    const [map, setMap] = useState(null);
    const [openConfirmLocationChange, setOpenConfirmLocationChange] = useState(false);
    console.log('[AddressDialog]');
    // location.coordinates?[location.coordinates.lat, location.coordinates.lng]:[shop.lat, shop.lng]

    const handleConfirmClose = ({target})=>{
        setOpenConfirmLocationChange(false);
        onClose(target);
    };

    const handleClose = ({target})=>{
        let center = map.getCenter();
        if(location && location.coordinates &&
            (location.coordinates.lat !== center.lat || location.coordinates.lng !== center.lng)){
            setOpenConfirmLocationChange(true);
        } else {
            onClose(target);
        }
    };

    const handleChange = ({target})=>{
        let newAddress = {...address, [target.name]: target.value};
        setAddress(newAddress);
    };

    const handleAddressChange = ({target})=>{
        let newContact = {...contact, [target.name]: target.value};
        setContact(newContact);
    };

    const handleSaveAddress = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
        let newAddress = {...address};
        if (map != null) {
            let c = map.getCenter();
            newAddress.lat = c.lat;
            newAddress.lng = c.lng;
        }
        httpClient.post(url, newAddress)
            .then(res => {
                setState({addNewAddress: false});
                tempLatLng = [newAddress.lat, newAddress.lng];
                currentTempLatLng = [newAddress.lat, newAddress.lng];
                onAddressChange();
                handleBlack();
            });
    };

    const handleDeleteAddress = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address/'+address._id;
        let newAddress = {...address};
        httpClient.delete(url, newAddress)
            .then(res => {
                onAddressChange();
                handleBlack();
            });
    };

    const handleNewAddress = () => {
        if (map != null) {
            let center = map.getCenter();
            currentTempLatLng = [center.lat, center.lng];
        }
        setAddress({_id: '', name: '', contact: '', mobile: '', address: ''});
        let newState = {addNewAddress: true};
        setState(newState);
    };

    const handleEditAddress = (address) => {
        if (map != null) {
            let center = map.getCenter();
            currentTempLatLng = [center.lat, center.lng];
            map.setView([address.lat, address.lng], 18);
            setPosition([address.lat, address.lng]);
        }
        setAddress(address);
        let newState = {addNewAddress: true};
        setState(newState);
    };

    const handleCurrentLocation = () => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(result=>{
                const coordinates = result.coords;
                if (map != null && coordinates) {
                    let z = map.getZoom();
                    map.setView([coordinates.latitude, coordinates.longitude], z);
                }
            }, function(positionError) {
                console.log('positionError', positionError);
            }, {enableHighAccuracy: true, maximumAge: 10000, timeout: 5000});
        }

    };

    const handleBlack = () => {
        if (map != null && currentTempLatLng) {
            let z = map.getZoom();
            map.setView([currentTempLatLng[0], currentTempLatLng[1]], z);
        }
        let newState = {addNewAddress: false};
        setState(newState);
    };

    const handleSelectAddress = (addressData) => {
        if (map != null) {
            //let z = map.getZoom();
            locationChanged = false;
            selectAddress = true;
            tempLatLng = [addressData.lat, addressData.lng];
            map.setView([addressData.lat, addressData.lng], 18);
            setPosition([addressData.lat, addressData.lng]);
            setContact({myAddress: true, name: addressData.name, contact: addressData.contact, mobile: addressData.mobile, address: addressData.address});
        }
    };

    const handleSelectLocation = () => {
        let newState = {addNewAddress: false};
        setState(newState);
        if (map != null) {
            let c = map.getCenter();
            let z = map.getZoom();
            let customer = JSON.parse(localStorage.getItem('customer'));
            let _contact = {...contact, myAddress: selectAddress};
            if(customer){
                if(!_contact.myAddress){
                    _contact.contact = customer.displayName;
                    _contact.name = customer.displayName;
                    _contact.address = contact.address;
                    if(customer.mobile){
                        _contact.mobile = customer.mobile;
                    }
                }

            }

            let gps = location.gps;
            onChange({type: 'SELECTED', gps: gps, zoom: z, coordinates:{accuracy: 0, lat: c.lat, lng: c.lng}, contact: _contact});
            setOpenConfirmLocationChange(false);
        }
    };

    return (
        <>
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                {!state.addNewAddress &&
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('address.delivery')}
                    </Typography>
                    {location.gps === 'NOT_ALLOW' &&
                    <IconButton
                        edge="end"
                        color="secondary"
                        aria-label="current location"
                        >
                        <LocationDisabledIcon/>
                    </IconButton>
                    }
                    {location.gps !== 'NOT_ALLOW' &&
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCurrentLocation}
                        aria-label="current location"
                        >
                        <MyLocationIcon/>
                    </IconButton>
                    }
                </Toolbar>
                }
                {state.addNewAddress &&
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleBlack}
                        aria-label="close"
                    >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('address.add')}
                    </Typography>
                    {location.gps === 'NOT_ALLOW' &&
                    <IconButton
                        edge="end"
                        color="secondary"
                        aria-label="current location"
                    >
                        <LocationDisabledIcon/>
                    </IconButton>
                    }
                    {location.gps !== 'NOT_ALLOW' &&
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCurrentLocation}
                        aria-label="current location"
                    >
                        <MyLocationIcon/>
                    </IconButton>
                    }
                </Toolbar>
                }
            </AppBar>
            <MapContainer center={location.coordinates?[location.coordinates.lat, location.coordinates.lng]:[shop.lat, shop.lng]}
                          zoom={location.zoom}
                          {...interactionOptions}
                          whenCreated={setMap}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {position[0] &&
                <LocationMarker effectOn={this}
                                position={location.coordinates?[location.coordinates.lat, location.coordinates.lng]:[shop.lat, shop.lng]} />
                }
                {shop && shop.lat && shop.lng &&
                <>
                    <Marker icon={markerIcon} position={[shop.lat, shop.lng] } ></Marker>
                    <Circle
                        center={{lat: shop.lat, lng:shop.lng}}
                        color="#1abc9c"
                        fillColor="#1abc9c"
                        radius={shop.distance * 1000}/>
                </>
                }

            </MapContainer>
            {addressList.show &&
            <div style={{height: '300px'}}>
                <div>
                {!state.addNewAddress &&
                <div>
                    <CardContent>
                        <Box mb={2}>
                            <TextField label={t('address.more')}
                                       fullWidth={true}
                                       name="address"
                                       value={contact.address}
                                       type="text"
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       onChange={handleAddressChange}/>
                        </Box>
                        <Button variant="contained"
                                size="large"
                                fullWidth={true}
                                onClick={handleSelectLocation}
                                style={{zIndex: 99, fontWeight: 'bold'}}>
                            <span>{t('common.confirm')}</span>
                        </Button>
                    </CardContent>
                    {addressList.loading &&
                    <Box display="flex" justifyContent="center" mt={4}>
                        <CircularProgress size={20}/>
                    </Box>
                    }
                    {!addressList.loading &&
                    <List component="nav"
                          subheader={
                              <ListSubheader component="div">
                                  <Box display="flex" justifyContent="space-between" alignItems="center">
                                      <span>{t('address.myAddress')}</span>
                                      <Button color="primary"
                                              onClick={handleNewAddress}
                                              startIcon={<AddCircleOutlineIcon/>}>
                                          {t('common.add')}
                                      </Button>
                                  </Box>
                              </ListSubheader>
                          }>
                        {
                            addressList.list.map((item) => (
                                <ListItem key={item._id}
                                          button
                                          onClick={e => handleSelectAddress(item)}
                                          divider={true}>
                                    <ListItemIcon><PersonPinCircleIcon/></ListItemIcon>
                                    <ListItemText primary={item.name} secondary={item.contact}/>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="edit" onClick={e=>{handleEditAddress(item)}}>
                                            <EditIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        }
                    </List>
                    }
                </div>
                }
                {state.addNewAddress &&
                <Box mt={1}>
                    <CardContent>
                        <form autoComplete="false" noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField label={t('address.name')}
                                               fullWidth={true}
                                               name="name"
                                               value={address.name}
                                               autoComplete="new-password"
                                               inputProps={{
                                                   autoComplete: 'new-password',
                                               }}
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               required
                                               onChange={handleChange}/>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField label={t('address.contact')}
                                               fullWidth={true}
                                               name="contact"
                                               value={address.contact}
                                               type="text"
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               required
                                               onChange={handleChange}/>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField label={t('address.mobile')}
                                               fullWidth={true}
                                               name="mobile"
                                               value={address.mobile}
                                               type="tel"
                                               InputProps={{
                                                   inputComponent: NumberFormatCustom,
                                               }}
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               required
                                               onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField label={t('address.more')}
                                               fullWidth={true}
                                               name="address"
                                               value={address.address}
                                               type="text"
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               required
                                               onChange={handleChange}/>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained"
                                            size="large"
                                            fullWidth={true}
                                            onClick={handleSaveAddress}
                                            style={{zIndex: 99, fontWeight: 'bold'}}>
                                        <span>{t('common.save')}</span>
                                    </Button>
                                </Grid>
                                {address._id &&
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained"
                                            size="large"
                                            color="secondary"
                                            fullWidth={true}
                                            onClick={handleDeleteAddress}
                                            style={{zIndex: 99, fontWeight: 'bold'}}>
                                        <span>{t('common.delete')}</span>
                                    </Button>
                                </Grid>
                                }
                                {!address._id &&
                                <Grid item xs={12} sm={12}>
                                    <Button variant="contained"
                                            size="large"
                                            color="inherit"
                                            fullWidth={true}
                                            onClick={handleBlack}
                                            style={{zIndex: 99, fontWeight: 'bold'}}>
                                        <span>{t('common.cancel')}</span>
                                    </Button>
                                </Grid>
                                }
                            </Grid>
                        </form>
                    </CardContent>
                </Box>
                }
                </div>
            </div>
            }
            {!addressList.show &&
            <Sticky mode="bottom">
                <CardContent>
                    <Button variant="contained"
                            size="large"
                            fullWidth={true}
                            onClick={handleSelectLocation}
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.confirm')}</span>
                    </Button>
                </CardContent>
            </Sticky>
            }
        </Dialog>
        <Modal
            open={openConfirmLocationChange}
            style={{overflow:'scroll'}}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className={classes.paper}>
                <Box mt={2}>
                    <Button variant="contained"
                            size="large"
                            disableElevation
                            fullWidth={true}
                            onClick={handleSelectLocation}
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('address.confirmChange')}</span>
                    </Button>
                </Box>
                <Box mt={2}>
                    <Button variant="contained"
                            size="large"
                            color="secondary"
                            disableElevation
                            fullWidth={true}
                            onClick={handleConfirmClose}
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.cancel')}</span>
                    </Button>
                </Box>
            </Box>
        </Modal>
        </>
    )
};