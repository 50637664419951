import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import ShopInfo from '../../Shop/ShopInfo'
import {httpClient} from "../../../core/HttpClient";

export default function Expired() {
    const { t } = useTranslation();
    const [state, setState] = useState({image:{}});
    const cid = localStorage.getItem('cid');
    console.log('[Expired]');
    useEffect(() => {
        console.log('Expired');
        httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account')
            .then(res => {
                if(res.data){
                    setState(res.data);
                }
            });
    }, [cid]);

    return (
        <>
        <Box>
            <Alert variant="filled" severity="warning" style={{borderRadius: 0}} >{t('common.message.expired')}</Alert>
        </Box>
        <Box p={2}>
            <ShopInfo shop={state} time={false} />
        </Box>
        </>
    );
}
