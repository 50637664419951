import React, { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import NumberFormat from 'react-number-format';
import {ProductView} from "../ProductView";

const useStyles = makeStyles({
    root: {},
    link: {
        color: '#1abc9c'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    qty: {
        minWidth: '1.5rem',
        textAlign: 'center'
    }
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let additional = '';

export default function ProductControlDialog({open, shop, product, item, onChange, onClose}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState({index: -1, additional: '', qty: 1, optional: {total: 0}});
    const [requireOptional, setRequireOptional] = useState(false);
    additional = item.additional;

    useEffect(() => {
        console.log('ProductControlDialog');
        let requireOpt = false;
        if(product.options){
            product.options.forEach(opt=>{
                if(!opt.multipleSelect){
                    requireOpt = true;
                }
            });
        }

        setRequireOptional(requireOpt);
        setState(item);
    }, [item, product]);

    const handleAdditionalChange = (value) => {
        additional = value
    };

    const handleOptionalChange = (value) => {
        let newState = {...state, optional: value};
        setState(newState);

        let requireOpt = false;
        if(product.options){
            product.options.forEach(opt=>{
                if(!opt.multipleSelect){
                    if(!value[opt._id]){
                        requireOpt = true;
                    }
                }
            });
        }
        setRequireOptional(requireOpt);
    };

    const handleIncrease = () => {
        if(product.remaining <= state.qty){
            return;
        }
        let newState = {...state, qty: ++state.qty};
        setState(newState);
    };

    const handleDecrease = () => {
        let newState = {...state, qty: --state.qty};
        setState(newState);
    };

    const handleAdd = () => {
        if(localStorage.getItem('token')){
            onChange({mode: 1, product: product, item: {...state, additional: additional}});
            additional = '';
        } else {
            localStorage.setItem('pid', JSON.stringify(product));

            const clientId = process.env.REACT_APP_LINE_CLIENT_ID;
            const redirectUri = `${process.env.REACT_APP_BASE_URL}/login?cid=${shop.cid}`;
            const state = Math.floor(100000 + Math.random() * 900000);

            const url =
                'https://access.line.me/oauth2/v2.1/authorize?response_type=code' +
                '&client_id=' +
                clientId +
                '&redirect_uri=' +
                redirectUri +
                '&state=' +
                state +
                '&scope=profile';

            window.location.href = url;
        }
    };

    const handleUpdate = () => {
        onChange({mode: 0, product: product, item: {...state, additional: additional}});
    };

    const handleRemove = () => {
        onChange({mode: -1, product: product, item: {...state, additional: additional}});
    };

    return (
        <Dialog fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}>
            <ProductView shopName={shop.name}
                         shopState={shop.state}
                         product={product}
                         item={item}
                         onClose={onClose}
                         onAdditionalChange={handleAdditionalChange}
                         onOptionalChange={handleOptionalChange}
            />
            <div style={
                {
                    position: 'fixed',
                    top: 'auto',
                    bottom: 0,
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    paddingBottom: '10px',
                    background: '#fff',
                    width: '100%',
                    borderRadius: '4px',
                    color: 'rgba(0, 0, 0, 0.87)',
                    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                    overflow: 'hidden',
                    zIndex: 999
                }
            }>
                {!product.oos && shop.state !== 'close' &&
                <Box display="flex" justifyContent="center" alignItems="center">
                    <IconButton aria-label="remove" disabled={state.qty === ((state.index < 0) ? 1 : 0)}
                                onClick={handleDecrease}>
                        <IndeterminateCheckBoxIcon fontSize="large"/>
                    </IconButton>
                    <Typography style={{fontSize: '1.1rem'}} className={classes.qty}>{state.qty}</Typography>
                    <IconButton aria-label="add" onClick={handleIncrease} disabled={product.remaining-product.cartQty <= state.qty}>
                        <AddBoxIcon fontSize="large"/>
                    </IconButton>
                </Box>
                }
                {shop.state === 'close' &&
                <Box mb={2}>
                    <Button style={{fontSize: '1.1rem'}}
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={true}
                            fullWidth={true}
                            disableElevation autoFocus>
                        <div>{t('shop.closed')}</div>
                    </Button>
                </Box>
                }
                {product.oos && shop.state !== 'close' &&
                <Box mb={2}>
                    <Button style={{fontSize: '1.1rem'}}
                            variant="contained"
                            size="large"
                            color="primary"
                            disabled={true}
                            fullWidth={true}
                            disableElevation autoFocus>
                            <div>{t('product.outOfStock')}</div>
                    </Button>
                </Box>
                }
                {!product.oos && shop.state !== 'close' &&
                <Box mb={2}>
                    {state.index < 0 &&
                    <Button style={{fontSize: '1.1rem'}}
                            variant="contained"
                            onClick={handleAdd}
                            size="large"
                            color="primary"
                            disabled={requireOptional}
                            fullWidth={true}
                            disableElevation autoFocus>
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%'}}>
                            <div>
                                {requireOptional?t('cart.selectRequireOption'):t('cart.addToCart')}
                            </div>
                            <div><NumberFormat value={(product.price + state.optional.total) * state.qty} displayType={'text'}
                                               thousandSeparator={true} prefix={'฿ '}/></div>
                        </Box>
                    </Button>
                    }
                    {state.index > -1 && state.qty > 0 &&
                    <Button style={{fontSize: '1.1rem'}}
                            variant="contained"
                            onClick={handleUpdate}
                            size="large"
                            color="primary"
                            fullWidth={true}
                            disableElevation autoFocus>
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%'}}>
                            <div>{t('common.withSave')}</div>
                            <div><NumberFormat value={(product.price + state.optional.total) * state.qty} displayType={'text'}
                                               thousandSeparator={true} prefix={'฿ '}/></div>
                        </Box>
                    </Button>
                    }
                    {state.index > -1 && state.qty < 1 &&
                    <Button style={{fontSize: '1.1rem'}}
                            variant="contained"
                            onClick={handleRemove}
                            size="large"
                            color="secondary"
                            fullWidth={true}
                            disableElevation autoFocus>
                        <div>{t('common.remove')}</div>
                    </Button>
                    }
                </Box>
                }
            </div>
        </Dialog>
    )
};