import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import NumberFormat from 'react-number-format';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {AttachmentPaySlip} from "../Upload/AttachmentPaySlip";
import ContactDialog from '../../components/Profile/ContactDialog'
import {Context} from "../../core/Context";
import {httpClient} from "../../core/HttpClient";
import CartUtil from "../../core/CartUtil";

const useStyles = makeStyles({
    root: {},
    paymentButton: {
        padding: '1rem'
    },
    BBL: {backgroundColor: '#264091', padding: '5px', borderRadius: '6px'},
    KBANK: {backgroundColor: '#039029', padding: '5px', borderRadius: '6px'},
    KTB: {backgroundColor: '#06a4e7', padding: '5px', borderRadius: '6px'},
    TTB: {backgroundColor: '#f58b19', padding: '5px', borderRadius: '6px'},
    SCB: {backgroundColor: '#462279', padding: '5px', borderRadius: '6px'},
    BAY: {backgroundColor: '#fec523', padding: '5px', borderRadius: '6px'},
    KKP: {backgroundColor: '#635f98', padding: '5px', borderRadius: '6px'},
    CIMBT: {backgroundColor: '#ff0000', padding: '5px', borderRadius: '6px'},
    TISCO: {backgroundColor: '#1369b0', padding: '5px', borderRadius: '6px'},
    UOBT: {backgroundColor: '#07367a', padding: '5px', borderRadius: '6px'},
    GHB: {backgroundColor: '#ff3a00', padding: '5px', borderRadius: '6px'},
    GSB: {backgroundColor: '#eb077e', padding: '5px', borderRadius: '6px'},
    ISBT: {backgroundColor: '#073e04', padding: '5px', borderRadius: '6px'}
});

export default function ConfirmOrder({selectedPayment}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const history = useHistory();
    const [context] = useContext(Context);
    const [open, setOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openContact, setOpenContact] = useState(false);
    const [attachment, setAttachment] = useState(null);

    console.log('[ConfirmOrder]');

    useEffect(() => {
        console.log('ConfirmOrder');
    }, []);

    const handleConfirmOrder = () => {

        let customerData = JSON.parse(localStorage.getItem('customer'));
        if(!customerData.mobile || !customerData.displayName){
            setOpenContact(true);
        } else {
            setOpen(true);
        }
    };

    const handleCopy = () => {
        setCopied(true);
    };

    const handleCloseCopied = () => {
        setCopied(false);
    };

    const handleConfirmPayment = () => {
        if(['foundsTransfer', 'promptPay'].indexOf(selectedPayment.method) > -1 && attachment){
            let paymentData = {
                method: selectedPayment.method,
                attachment: attachment
            };
            submitConfirmOrder(paymentData);
        } else if(selectedPayment.method === 'cash') {
            let paymentData = {
                method: selectedPayment.method
            };
            submitConfirmOrder(paymentData);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseContact = (value) => {
        setOpenContact(false);
        if(value === 1){
            setOpen(true);
        }
    };

    function  submitConfirmOrder(paymentData) {
        setLoading(true);
        setOpen(false);
        let cacheCustomer = JSON.parse(localStorage.getItem('customer'));
        let cacheData = JSON.parse(localStorage.getItem('cache'));
        let _location = cacheData.location;
        if(!_location.contact){
            _location.contact = cacheCustomer;
        } else {
            if(!_location.contact.name){
                _location.contact.name = cacheCustomer.displayName;
            }
            if(!_location.contact.mobile){
                _location.contact.mobile = cacheCustomer.mobile;
            }
            if(!_location.contact.address){
                _location.contact.address = cacheCustomer.address;
            }
        }
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/order/confirm';
        let data = {
            cart: cacheData.cart,
            location:  _location,
            deliver: context.mode,
            payment: {
                method: paymentData.method,
                payDate: new Date(),
                attachment: paymentData.attachment
            }
        };

        httpClient.post(url, data)
            .then(res => {
                if(res.data){
                    new CartUtil(cid).clearCart();
                    history.push({
                        pathname: `/m/${cid}`
                    });
                    setLoading(false);
                }
            }).catch(e=>{
                setLoading(false);
                setOpen(true);
            });
    }

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} mt={2}>
                <Typography style={{fontSize: '1.1rem'}}
                            fontWeight="fontWeightBold">
                    {t('common.grandTotal')}
                </Typography>
                <Typography style={{fontSize: '1.1rem'}}
                            fontWeight="fontWeightBold">
                    <NumberFormat value={context.paymentAmount + context.deliveryPrice} displayType={'text'} thousandSeparator={true} prefix={'฿ '} />
                </Typography>
            </Box>
            <Box mb={2}>
                <Button
                    style={{fontSize: '1.1rem'}}
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth={true}
                    size="large"
                    className={classes.confirmOrderButton}
                    onClick={handleConfirmOrder}
                    disabled={selectedPayment.method === ''}
                >
                    {t('common.orderNow')}
                </Button>
            </Box>
            {openContact &&
            <ContactDialog open={openContact} onClose={handleCloseContact} />
            }
            <Dialog open={open}
                    scroll="body"
                    fullWidth={true}
                    onClose={handleClose}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} ml={2} mr={2}>
                    <Typography>
                        {t('payment.amount')}
                    </Typography>
                    <Typography>
                        <NumberFormat value={context.paymentAmount + context.deliveryPrice} displayType={'text'} thousandSeparator={true} prefix={'฿ '} />
                    </Typography>
                </Box>
                {selectedPayment.method === 'cash' &&
                <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                    ** {context.mode === 'DELIVERY'?t('payment.cashDescription'):t('payment.cashDescriptionPickup')} **
                </Box>
                }
                {selectedPayment.method === 'foundsTransfer' &&
                <>
                    <List component="nav" subheader={
                        <ListSubheader component="div" disableSticky={true}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <span>{t('payment.bankAccount')}</span>
                            </Box>
                        </ListSubheader>
                    }>
                        {selectedPayment.foundsTransferList && selectedPayment.foundsTransferList.map(item => {
                            return (
                                <CopyToClipboard key={item.bank} text={item.accountNumber} onCopy={() => handleCopy()}>
                                    <ListItem dense role={undefined}
                                              secondaryAction={
                                                  <IconButton edge="end" aria-label="copy">
                                                      <ContentCopyIcon />
                                                  </IconButton>
                                              }>
                                        <ListItemIcon>
                                            <div style={{width: '32px'}}>
                                                <img className={classes[item.bank]} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/banks/${item.bank}.svg`} alt={item.code} />
                                            </div>
                                        </ListItemIcon>
                                        <ListItemText id='foundsTransfer'>
                                            <Typography variant="h6" noWrap={true}>
                                                <div style={{fontSize: '1rem'}}>
                                                    <NumberFormat value={item.accountNumber} displayType={'text'} format="###-#-#####-#####" />
                                                </div>
                                                <div style={{fontSize: '0.8rem'}}>
                                                    {item.accountName}
                                                </div>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                </CopyToClipboard>
                            )
                        })}
                    </List>
                    <Box m={2}>
                        <AttachmentPaySlip value={attachment} onChange={setAttachment} />
                    </Box>
                </>
                }
                {selectedPayment.method === 'promptPay' &&
                <>
                    <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
                        <Typography variant="caption" display="block" gutterBottom style={{color: 'gray'}}>
                            {t('payment.promptPayDetail')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                        <img alt="qrcode" src={`${process.env.REACT_APP_CDN_BASE_URL}/${selectedPayment.promptPayQrCodeImage.permission}/o/${selectedPayment.promptPayQrCodeImage.name}`} style={{width: '100%'}} />
                    </Box>
                    <Box m={2}>
                        <AttachmentPaySlip value={attachment} onChange={setAttachment} />
                    </Box>
                </>
                }
                <Box m={2}>
                    <Button variant="contained"
                            color="success"
                            size="large"
                            onClick={handleConfirmPayment}
                            fullWidth={true}
                            disabled={['foundsTransfer', 'promptPay'].indexOf(selectedPayment.method) > -1 && attachment === null}>
                        {t('payment.confirmOrder')}
                    </Button>
                </Box>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
            <Snackbar open={copied}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseCopied}>
                <Alert variant="filled" onClose={handleCloseCopied} severity="info">
                    {t('payment.copiedBankAccount')}
                </Alert>
            </Snackbar>
        </div>
    );
}
