import React from 'react';
import { Route, Router } from 'react-router';
import { Redirect, Switch } from 'react-router-dom';
import Home from './components/Home';
import Cart from './components/Cart';
import Login from './components/Login';
import Dashboard from './components/Profile/Dashboard';
import MyInformation from './components/Profile/MyInformation';
import OrdersHistory from './components/Profile/OrdersHistory';
import OrdersInfo from './components/Profile/OrdersInfo';
import LandingPage from "./components/LandingPage";
import Expired from "./components/Common/Expired";
import Publish from './layouts/Publish';
import Inside from './layouts/Inside';
import Blank from './layouts/Blank';

const Routes = (props) => (
    <Router {...props}>
        <Switch>
            <Blank path="/login" component={Login} />
            <Inside path="/m/o/:cid/:uid" component={OrdersInfo} title="profile.ordersInformation" backTo="store" />
            <Inside path="/m/:cid/c" component={Cart} title="cart.myCart" backTo="home" />
            <Publish path="/m/:cid" component={Home} />
            <Route path="/m" component={LandingPage} />
            <Blank path="/e/:cid" component={Expired} />
            <Blank path="/e" component={Expired} />
            <Inside path="/p" component={MyInformation} title="profile.myInformation" backTo="store" />
            <Inside path="/h/:cid/:uid" component={OrdersInfo} title="profile.ordersHistory" backTo="history" />
            <Inside path="/h/:cid" component={OrdersHistory} title="profile.ordersHistory" backTo="store" />
            <Inside path="/" component={Dashboard} title="profile.myProfile" backTo="store" />
            <Redirect to="/" />
        </Switch>
    </Router>
);

export default Routes;