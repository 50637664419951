import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Routes from './routes';
import './i18n/config';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Create your Own theme:
const theme = createTheme({
    palette: {
        primary: {
            main: '#1abc9c',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#f5404c',
        },
        orange: {
            main: '#ffa600',
        },
        success: {
            main: '#44cc00',
            contrastText: '#ffffff',
        },
        light: {
            main: '#ffffff'
        }
    }
});

const history = createBrowserHistory();
ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Routes history={history} />
    </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
