import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import NumberFormat from 'react-number-format';
import {httpClient} from "../../../core/HttpClient";


const PhoneFormatCustom = React.forwardRef(function PhoneFormatCustom(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});


export default function ContactDialog({open, onClose}) {
    const { t } = useTranslation();
    const [contact, setContact] = useState({...JSON.parse(localStorage.getItem('customer'))});
    const [error, setError] = useState({displayName: false, mobile: false});
    console.log('[ContactDialog]');

    const handleSave = () => {
        if(_validation()){
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/profile';
            httpClient.put(url, contact)
                .then(res => {
                    if(res.data){
                        let customer = {displayName: res.data.displayName, mobile: res.data.mobile, pictureUrl: res.data.pictureUrl};
                        localStorage.setItem('customer', JSON.stringify(customer));
                        onClose(1);
                    } else {
                        let cid = localStorage.getItem('cid');
                        localStorage.clear();
                        window.location.href = `/m/${cid}`;
                    }
                }).catch(e=>{
                    let cid = localStorage.getItem('cid');
                    localStorage.clear();
                    window.location.href = `/m/${cid}`;
                });
        }
    };

    const handleChange = ({ target }) => {
        setContact({ ...contact, [target.name]: target.value });
    };

    function _validation() {
        let result = true;
        let error = {displayName: false, mobile: false};
        if(!contact.displayName){
            result = false;
            error.displayName = true;
        }
        if(!contact.mobile ||
            contact.mobile.length !== 10 ||
            !contact.mobile.startsWith('0')
        ){
            result = false;
            error.mobile = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    return (
        <Dialog open={open}
                onClose={e=>onClose(0)}
                fullWidth={true}>
            <AppBar elevation={0} position="static" color="light" sx={{ position: 'relative' }}>
                <Toolbar>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div">
                        {t('profile.contact')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent dividers>
                <Box>
                    <TextField id="contactName"
                               label={t('profile.name')}
                               variant="outlined"
                               fullWidth={true}
                               error={error.displayName}
                               inputProps={{
                                   autoComplete: 'new-password',
                               }}
                               name="displayName" value={ contact.displayName } onChange={ handleChange } required />
                </Box>
                <Box mt={2}>
                    <TextField id="contactMobile"
                               label={t('profile.mobile')}
                               variant="outlined"
                               fullWidth={true}
                               name="mobile" type="tel"
                               value={contact.mobile}
                               onChange={ handleChange }
                               error={error.mobile}
                               InputProps={{
                                   inputComponent: PhoneFormatCustom,
                               }}
                               required />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" fullWidth={true} size="large" disableElevation onClick={handleSave}>
                    {t('common.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
};