import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import AddressDelivery from '../AddressDelivery'
import AddressStatic from '../AddressStatic'
import {httpClient} from "../../../core/HttpClient";
import {Context} from "../../../core/Context";

let DEFAULT_COORDINATES = {accuracy: 0, lat: 13.724168703054607, lng: 100.52801370653471}; // Default Bangkok
let DEFAULT_ZOOM = 17;
let DEFAULT_LOCATION = {coordinates: DEFAULT_COORDINATES, gps: 'UNDEFINED', zoom: DEFAULT_ZOOM, type: 'NO_LOCATION', contact: null};

function _distance(lat1,
                   lat2, lon1, lon2)
{

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 =  lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2),2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    return(c * r);
}

export default function Delivery({shop, onLocationChange}) {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [location, setLocation] = useState(DEFAULT_LOCATION);
    const [open, setOpen] = useState(false);
    const [addressList, setAddressList] = useState({show: false, loading: false, list: []});
    
    useEffect(() => {
        let cacheData = JSON.parse(localStorage.getItem('cache'));
        let customerData = JSON.parse(localStorage.getItem('customer'));
        if(cacheData){
            if(dayjs().diff(cacheData.date, 'hour', true) < 2){
                if(cacheData.location){
                    let contactData = cacheData.location.contact;
                    if(contactData && contactData.myAddress){
                        setLocation({...cacheData.location, contact: contactData});
                    } else {
                        if(customerData){
                            if(customerData.displayName){
                                contactData.name = customerData.displayName;
                            }
                            if(customerData.mobile){
                                contactData.mobile = customerData.mobile;
                            }
                            if(customerData.address){
                                contactData.address = customerData.address;
                            }
                        }

                        setLocation({...cacheData.location, contact: contactData});
                    }
                }
            } else {
                return history.push({
                    pathname: `/m/${cid}`,
                    historyData: {showFoodOrder: false}
                });
            }
        } else {
            return history.push({
                pathname: `/m/${cid}`,
                historyData: {showFoodOrder: false}
            });
        }
    }, [history, cid]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {

        let token = localStorage.getItem('token');
        if(token){
            // load address
            setAddressList({show: true, loading: true, list: []});
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';

            httpClient.get(url)
                .then(res => {
                    if(res.data){
                        setAddressList({show: true, loading: false, list: res.data});
                    } else {
                        setAddressList({show: true, loading: false, list: []});
                    }

                    setOpen(true);
                });
        } else {
            setOpen(true);
        }
    };

    const handleAddressChange = (customerLocation) => {
        console.log('handleAddressChange');
        let newMyLocation = {...location, ...customerLocation};
        setLocation(newMyLocation);

        let cacheData = JSON.parse(localStorage.getItem('cache'));
        if(cacheData && cacheData.cid === cid){
            let newCacheData = {...cacheData, date: new Date(), location: customerLocation};
            localStorage.setItem('cache', JSON.stringify(newCacheData));
            const cd = _distance(customerLocation.coordinates.lat, shop.lat,
                customerLocation.coordinates.lng, shop.lng);
            let outOfDelivery = 0;
            if(cd > shop.distance){
                outOfDelivery = 1;
            }

            let distance = Math.floor(cd * 10) / 10;
            let deliveryPrices = shop.deliveryPrices;
            let d = Math.floor(distance);
            let ds = Math.floor((distance - d) * 10) / 10;
            let price = shop.deliveryStartPrice || 0;
            if(deliveryPrices){
                for (let i = 0; i < d; i++) {
                    if(deliveryPrices[i]){
                        price += deliveryPrices[i];
                    }
                }
                if(ds > 0){
                    if(deliveryPrices[d]){
                        price += Math.floor(deliveryPrices[d] * ds);
                    }
                }
            }

            setContext({...context, outOfDelivery: outOfDelivery, deliveryPrice: price});
            onLocationChange({distance: distance, price: price});
        }
        setOpen(false);
    };

    return (
        <div>
            {location.contact &&
            <>
                <Card sx={{borderRadius: '0px'}} pt={2}>
                    <CardHeader style={{paddingBottom: '5px'}} title={<Typography variant="h6" component="div">
                            {t('address.delivery')}
                        </Typography>} />
                    <Box style={{margin: '16px',
                        border: '1px solid #e7e7e7',
                        borderRadius: '5px'}}>
                        <ButtonBase onClick={handleClick} disableTouchRipple={true} style={{cursor: 'pointer', height: '120px', width: '100%'}}>
                            <AddressStatic shop={shop} location={location} />
                        </ButtonBase>
                        <ButtonBase onClick={handleClick} disableTouchRipple={true} style={{cursor: 'pointer', width: '100%'}}>
                            <CardContent style={{width: '100%', textAlign: 'left'}}>
                                <Box>
                                    <Box style={{marginBottom: '5px'}}>
                                        <Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                        {t('address.contact')} : {location.contact.contact}
                                        </Typography>
                                    </Box>
                                    <Box style={{marginBottom: '5px'}}>
                                        <Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                        {t('address.mobile')} : {location.contact.mobile && <NumberFormat value={location.contact.mobile} displayType={'text'} format="###-###-####" />}{!location.contact.mobile && <span>{t('common.notYetSpecify')}</span>}
                                        </Typography>
                                    </Box>
                                    {location.contact.address &&
                                    <Box>
                                        <Typography variant="body2" component="div" style={{flexGrow: 1}}>
                                            {t('address.title')} : {location.contact.address}
                                        </Typography>
                                    </Box>
                                    }
                                </Box>
                            </CardContent>
                        </ButtonBase>
                    </Box>
                </Card>
                <AddressDelivery open={open} shop={shop} location={location} onClose={handleClose} onChange={handleAddressChange} />
            </>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={addressList.loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </div>
    );
}
