import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
    root: {},
    footer: {
        background: '#ffffff'
    }
});

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
            <Box display="flex" justifyContent="center" p={2}>
                <Typography>
                    Power by <a href="https://foodkub.com" target="_blank" rel="noopener noreferrer"
                                style={{color: '#1abc9c', textDecoration: 'none'}}>FoodKub</a> v.{process.env.REACT_APP_VERSION}
                </Typography>
            </Box>
        </div>
    );
}
