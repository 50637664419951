import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import AddressDelivery from '../AddressDelivery'
import AddressStatic from '../AddressStatic'

export default function DeliveryView({shop, location}) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {location.contact &&
            <>
                <Card sx={{borderRadius: '0px'}} pt={2}>

                    <Box display="flex" justifyContent="center" mt={1}>
                        <Typography variant="h6" component="div">
                            {t('address.delivery')}
                        </Typography>
                    </Box>
                    <Box style={{margin: '16px',
                        border: '1px solid #e7e7e7',
                        borderRadius: '5px'}}>
                        <Box style={{height: '256px'}}>
                            <AddressStatic shop={shop} location={location} />
                        </Box>
                        <CardContent style={{width: '100%', textAlign: 'left'}}>
                            <Box>
                                <Box style={{marginBottom: '5px'}}>
                                    <Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                        {t('address.contact')} : {location.contact.name}
                                    </Typography>
                                </Box>
                                <Box style={{marginBottom: '5px'}}>
                                    <Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                        {t('address.mobile')} : {location.contact.mobile && <NumberFormat value={location.contact.mobile} displayType={'text'} format="###-###-####" />}{!location.contact.mobile && <span>{t('common.notYetSpecify')}</span>}
                                    </Typography>
                                </Box>
                                {location.contact.address &&
                                <Box>
                                    <Typography variant="body2" component="div" style={{flexGrow: 1}}>
                                        {t('address.title')} : {location.contact.address}
                                    </Typography>
                                </Box>
                                }
                            </Box>
                        </CardContent>
                    </Box>
                </Card>
                <AddressDelivery open={open} shop={shop} location={location} onClose={handleClose} />
            </>
            }
        </div>
    );
}
