import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CartList from "./CartList";
import Delivery from "./../Location/Delivery";
import PaymentChannel from "./../Payment/PaymentChannel";
import ModeSelection from '../../components/ModeSelection'

import {httpClient} from "../../core/HttpClient";
import ConfirmOrder from "../ConfirmOrder";
import {Context} from "../../core/Context";
import {saveMode, getModePermissions} from "../../core/LocalStorageUtil";

export default function Cart() {
    const { t } = useTranslation();
    const { cid } = useParams();
    const [context, setContext] = useContext(Context);
    const [state, setState] = useState({name: {}, image:{}});
    const [selectedPayment, setSelectedPayment] = useState({method: ''});
    const [initial, setInitial ] = useState(0);
    const [deliveryPrice, setDeliveryPrice ] = useState({distance: 0, price: 0});

    console.log('[Cart]', context);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setState(res.data);
                    setInitial(1);
                }
            });
    }, []);

    const handlePaymentChange = (value) => {
        setSelectedPayment(value);
    };

    const handleLocationChange = (value) => {
        setDeliveryPrice(value);
    };

    const handleSelectMode = (value) => {
        let permissions = getModePermissions() || [];
        saveMode({mode: value, cid: cid, permissions: permissions});
        setContext({...context, mode: value});
    };

    return (
        <div>
            {initial === 0 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {initial === 1 &&
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <CartList shop={state} delivery={deliveryPrice} mode={context.mode} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card sx={{borderRadius: '0px'}} pt={2}>
                        <CardHeader style={{paddingBottom: '5px'}} title={<Typography variant="h6" component="div">
                            {t('deliver.method')}
                        </Typography>} />
                        <CardContent>
                            <Box p={1} display="flex" alignItems="center">
                                <ModeSelection mode={context.mode} exit={true} onSelect={handleSelectMode} />
                            </Box>
                        </CardContent>
                    </Card>
                    {context.mode === 'DELIVERY' &&
                    <Box pb={1}>
                        <Delivery shop={state} onLocationChange={handleLocationChange} />
                    </Box>
                    }
                    <Box>
                        {(context.outOfDelivery === 1 && context.mode === 'DELIVERY') &&
                        <CardContent>
                            <Alert variant="filled" severity="warning">{t('common.message.outOfDelivery')}</Alert>
                        </CardContent>
                        }
                        {(context.outOfDelivery === 0 || context.mode === 'PICKUP') &&
                        <>
                            <PaymentChannel mode={context.mode} onChange={handlePaymentChange} />
                            <CardContent>
                                <ConfirmOrder selectedPayment={selectedPayment}/>
                            </CardContent>
                        </>
                        }
                    </Box>
                </Grid>
            </Grid>
            }
        </div>
    );
}
