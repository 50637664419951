import React, { useState, useRef, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import {isDesktop} from 'react-device-detect';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Circle, useMapEvents } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './style.css'

const markerIcon = new L.Icon({
    iconUrl: 'https://cdn.foodkub.com/public/assets/fkmark.png',
    iconRetinaUrl: 'https://cdn.foodkub.com/public/assets/fkmark2.png',
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 44],
    iconAnchor: [15, 44],
    className: 'leaflet-div-icon'
});

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

const blueIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|237cc9&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    }),
    redIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LocationMarker = (props) => {
    const [icon, setIcon] = useState(redIcon);
    const [position, setPosition] = useState(props.position);
    const markerRef = useRef(null);
    const map = useMapEvents({
        move() {
            const marker = markerRef.current;
            if (marker != null) {
                marker.setLatLng(map.getCenter());
            }
        },
        moveend() {
            let center = map.getCenter();
            if(position){
                if(position[0] !== center.lat || position[1] !== center.lng){
                    setIcon(blueIcon);
                } else {
                    setIcon(redIcon);
                }
                setPosition([center.lat, center.lng]);
            } else {
                setIcon(blueIcon);
                setPosition([center.lat, center.lng]);
            }

            // clear address when change location.
            if(props.onLocationChanged){
                props.onLocationChanged();
            }

        }
    });

    return position === null ? null : (
        <Marker position={position} ref={markerRef} icon={icon}></Marker>
    )
};

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

export default function AddressDelivery({open, location, shop, onChange, onClose}) {
    const { t } = useTranslation();
    const [state, setState] = useState({addNewAddress: false});
    const [address, setAddress] = useState(location.contact);
    const [error, setError] = useState({name: false, mobile: false});
    const [map, setMap] = useState(null);
    let tempAddress = location.contact;
    console.log('[AddressDelivery]');
    const handleChange = ({target})=>{
        let newAddress = {...address, [target.name]: target.value};
        setAddress(newAddress);
    };

    const handleClose = () => {
        let newAddress = {...tempAddress};
        setAddress(newAddress);
        onClose();
    };

    const handleSave = () => {
        if(_validation()){
            let _contact = {myAddress: false, name: address.name, contact: address.contact, mobile: address.mobile, address: address.address};
            let c = map.getCenter();
            let z = map.getZoom();
            onChange({type: location.type, gps: location.gps, name: address.name, contact: _contact, zoom: z, coordinates:{accuracy: 0, lat: c.lat, lng: c.lng}});
        }

    };

    const handleCurrentLocation = () => {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(result=>{
                const coordinates = result.coords;
                if (map != null && coordinates) {
                    let z = map.getZoom();
                    map.setView([coordinates.latitude, coordinates.longitude], z);
                }
            }, function(positionError) {
                console.log('positionError', positionError);
            }, {enableHighAccuracy: true, maximumAge: 10000, timeout: 5000});
        }

    };

    const handleLocationChanged = () => {
        let newAddress = {...address, address: ''};
        setAddress(newAddress);
    };

    const handleBlack = () => {
        let newState = {addNewAddress: false};
        setState(newState);
    };

    function _validation() {
        let result = true;
        let error = {name: false, mobile: false};
        if(!address.name){
            result = false;
            error.name = true;
        }
        if(!address.mobile ||
            address.mobile.length !== 10 ||
            !address.mobile.startsWith('0')
        ){
            result = false;
            error.mobile = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                {!state.addNewAddress &&
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('address.delivery')}
                    </Typography>
                    {location.gps === 'NOT_ALLOW' &&
                    <IconButton
                        edge="end"
                        color="secondary"
                        aria-label="current location"
                        >
                        <LocationDisabledIcon/>
                    </IconButton>
                    }
                    {location.gps !== 'NOT_ALLOW' &&
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCurrentLocation}
                        aria-label="current location"
                        >
                        <MyLocationIcon/>
                    </IconButton>
                    }
                </Toolbar>
                }
                {state.addNewAddress &&
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleBlack}
                        aria-label="close"
                    >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('address.add')}
                    </Typography>
                    {location.gps === 'NOT_ALLOW' &&
                    <IconButton
                        edge="end"
                        color="secondary"
                        aria-label="current location"
                    >
                        <LocationDisabledIcon/>
                    </IconButton>
                    }
                    {location.gps !== 'NOT_ALLOW' &&
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCurrentLocation}
                        aria-label="current location"
                    >
                        <MyLocationIcon/>
                    </IconButton>
                    }
                </Toolbar>
                }
            </AppBar>
            <MapContainer center={location.coordinates?[location.coordinates.lat, location.coordinates.lng]:[shop.lat, shop.lng]}
                          zoom={location.zoom}
                          {...interactionOptions}
                          whenCreated={setMap}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker effectOn={this}
                                onLocationChanged={handleLocationChanged}
                                position={location.coordinates?[location.coordinates.lat, location.coordinates.lng]:[shop.lat, shop.lng]} />
                {shop && shop.lat && shop.lng &&
                <>
                <Marker icon={markerIcon} position={[shop.lat, shop.lng] } ></Marker>
                <Circle
                    center={{lat: shop.lat, lng:shop.lng}}
                    color="#1abc9c"
                    fillColor="#1abc9c"
                    radius={shop.distance * 1000}/>
                </>
                }

            </MapContainer>
            <div style={{height: '300px', marginTop: '10px'}}>
                <CardContent>
                    <form autoComplete="false" noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.contact')}
                                           fullWidth={true}
                                           name="contact"
                                           value={address.contact}
                                           type="text"
                                           error={error.name}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.mobile')}
                                           fullWidth={true}
                                           name="mobile"
                                           value={address.mobile}
                                           type="tel"
                                           InputProps={{
                                               inputComponent: NumberFormatCustom,
                                           }}
                                           error={error.mobile}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.title')}
                                           fullWidth={true}
                                           name="address"
                                           value={address.address}
                                           type="text"
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12} sm={12} mb={2}>
                                <Button variant="contained"
                                        size="large"
                                        fullWidth={true}
                                        onClick={handleSave}
                                        style={{zIndex: 99, fontWeight: 'bold'}}>
                                    <span>{t('common.save')}</span>
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </div>
        </Dialog>
    )
};