import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './style.css'

const redIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

const LocationMarker = (props) => {
    const [position] = useState(props.position);
    const markerRef = useRef(null);

    const marker = markerRef.current;
    if (marker != null) {
        marker.setLatLng(props.position);
    }

    return position === null ? null : (
        <Marker position={position} ref={markerRef} icon={redIcon}></Marker>
    )
};

const interactionOptions = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    zoomSnap: false,
    zoomDelta: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false,
};

export default function AddressStatic({location}) {
    const [map, setMap] = useState(null);
    console.log('[AddressStatic]');
    if (map != null) {
        map.setView([location.coordinates.lat, location.coordinates.lng], location.zoom);
    }

    return (
        <Box style={{width: 'inherit', height: 'inherit'}}>
            <MapContainer className="static-map"
                          center={[location.coordinates.lat, location.coordinates.lng]}
                          zoom={location.zoom}
                          {...interactionOptions}
                          whenCreated={setMap}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker effectOn={this} position={[location.coordinates.lat, location.coordinates.lng]} />
            </MapContainer>
        </Box>
    )
};