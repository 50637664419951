import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MyInformation from "../MyInformation";
import OrdersHistory from "../OrdersHistory";
import {httpClient} from "../../../core/HttpClient";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    link: {
        color: '#1abc9c'
    },
    tabs: {
        borderBottom: '1px solid #e8e8e8',

    },
    tabPanels: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
});

function TabPanel(props) {
    const {children, value, index, classes, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Container disableGutters={true}>
                    <Box>
                        {children}
                    </Box>
                </Container>
            )}
        </div>
    );
}

export default function Dashboard() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    console.log('Dashboard');
                }
            });
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <div>
            <Tabs value={tabIndex}
                  className={classes.tabs}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  onChange={handleTabChange}
                  aria-label="info tab">
                <Tab label={t('profile.myInformation')} />
                <Tab label={t('profile.ordersHistory')} />
            </Tabs>
            <TabPanel className={classes.tabPanels} value={tabIndex} index={0}>
                <MyInformation />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <OrdersHistory />
            </TabPanel>
        </div>
    );
}
