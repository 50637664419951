import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../../core/HttpClient";
import {TextI18n} from "../../TextI18n";
import {DateTime} from "../../DateTime";

export default function OrdersHistory() {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        console.log('OrdersHistory');
        let url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/orders/history';
        httpClient.get(url)
            .then(res => {
                if(Array.isArray(res.data)){
                    setTransactions(res.data);
                }
                setLoading(false);
            }).catch(e=>{
                let _cid = cid || localStorage.getItem('cid');
                history.push(`/m/${_cid}`);
            });
    }, [cid, history]);

    const handleClickItem = (foodOrder)=>{
        history.push(`/h/${cid}/${foodOrder.uid}`);
    };

    return (
        <div>
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <Box>
                {transactions.length === 0 &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <Typography variant="body1" color="textSecondary"
                                sx={{fontSize: '0.85rem'}}>{t('common.notFound')}</Typography>
                </Box>
                }
                {transactions.length > 0 &&
                <List className="SortableList">
                    {transactions.map((value, index) => (
                        <ListItem key={`item-${value.foodOrders._id}`}
                                  divider={index !== transactions.length - 1 ? true : false}
                                  onClick={e => handleClickItem(value.foodOrders)}
                                  button>
                            <ListItemText>
                                <div>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography noWrap={true}>
                                            <span>
                                                {value.foodOrders.orderNo}
                                            </span>
                                        </Typography>
                                        <div>
                                            <span><DateTime value={value.foodOrders.orderDate}
                                                            format="DD MMM YYYY HH:mm"></DateTime></span>
                                        </div>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography noWrap={true}>
                                        <span>
                                            <TextI18n value={value.foodOrders.accountInfo.name}></TextI18n>
                                        </span>
                                        </Typography>
                                        <div>
                                            <span>{t(`order.${value.foodOrders.status}`)}</span>
                                        </div>
                                    </Box>
                                </div>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
                }
            </Box>
            }
        </div>
    );
}
