import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ShopInfo from '../Shop/ShopInfo'
import StoreLocation from '../Location/StoreLocation'
import AddressSelection from '../Location/AddressSelection'
import ProductList from '../Product/ProductList'
import {httpClient} from "../../core/HttpClient";
import {Context} from "../../core/Context";
import {getMode} from "../../core/LocalStorageUtil";

export default function Home() {
    const { t } = useTranslation();
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [state, setState] = useState({image:{}});
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);

    let mode = getMode();

    console.log('[Home]');
    useEffect(() => {
        httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account')
            .then(res => {
                if(res.data){
                    if(res.data.expired){
                        history.push('/e');
                    }
                    setState(res.data);
                }
            });

        httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account/products')
            .then(res => {
                if(res.data){
                    setList(res.data);
                }
                setLoading(false);
            });
    }, [history]);

    const handleSelectLocation = (context) => {
        console.log('handleSelectLocation');
        let newContext = {...context, openMap: true};
        setContext(newContext);
    };

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={5}>
                    <ShopInfo shop={state} time={true} />
                </Grid>
                <Grid item xs={12} md={7}>
                {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
                }
                {mode === 'DELIVERY' &&
                <Box>
                    <AddressSelection context={context} onChange={handleSelectLocation} />
                    {!loading && (context.outOfDelivery === 1 && mode === 'DELIVERY') &&
                    <Box pl={2}>
                        <Typography variant="caption" display="block" color="error" gutterBottom>
                            * {t('common.message.outOfDeliveryAddress')}
                        </Typography>
                    </Box>
                    }
                </Box>
                }
                <Divider style={{'marginBottom': '5px', 'marginTop': '5px'}} />
                {!loading && (context.outOfDelivery === 0 || mode === 'PICKUP' || mode === 'VIEW') &&
                    <ProductList list={list} shop={state}/>
                }
                {!loading && (context.outOfDelivery === 1 && mode === 'DELIVERY') &&
                <Box style={{height: '350px'}}>
                    <StoreLocation shop={context.shop} location={context.location} />
                </Box>
                }
                </Grid>
            </Grid>
        </div>
    );
}
