import dayjs from 'dayjs';

export function getCache(cid) {
    let cacheData = JSON.parse(localStorage.getItem('cache'));
    if(cacheData && cacheData.cid === cid){
        if(dayjs().diff(cacheData.date, 'hour', true) > 2){
            cacheData = {cid: cid, date: new Date(), location: null, cart: null};
        }
    } else {
        cacheData = {cid: cid, date: new Date(), location: null, cart: null};
    }

    return cacheData;
}

export function getMode() {
    let cacheData = JSON.parse(localStorage.getItem('mode'));
    let cid = localStorage.getItem('cid');
    if(cacheData && cacheData.date){
        if(dayjs().diff(cacheData.date, 'hour', true) > 2){
            cacheData = null;
        } else {
            if(cid === cacheData.cid){
                cacheData = cacheData.mode;
            } else{
                cacheData = null;
            }
        }
    } else {
        cacheData = null;
    }

    return cacheData;
}

export function getModePermissions() {
    let cacheData = JSON.parse(localStorage.getItem('mode'));
    let cid = localStorage.getItem('cid');
    if(cacheData && cacheData.date){
        if(dayjs().diff(cacheData.date, 'hour', true) > 2){
            cacheData = null;
        } else {
            if(cid === cacheData.cid){
                cacheData = cacheData.permissions;
            } else{
                cacheData = null;
            }
        }
    } else {
        cacheData = null;
    }

    return cacheData;
}

export function saveCache(cacheData) {
    cacheData.date = new Date();
    localStorage.setItem('cache', JSON.stringify(cacheData));
}

export function saveMode(cacheData) {
    cacheData.date = new Date();
    localStorage.setItem('mode', JSON.stringify(cacheData));
}